
export const countries = [
    { value: "Afghanistan", text: "Afghanistan" },
    { value: "Afrique du Sud", text: "Afrique du Sud" },
    { value: "Albanie", text: "Albanie" },
    { value: "Algérie", text: "Algérie" },
    { value: "Allemagne", text: "Allemagne" },
    { value: "Andorre", text: "Andorre" },
    { value: "Angola", text: "Angola" },
    { value: "Anguilla", text: "Anguilla" },
    { value: "Antarctique", text: "Antarctique" },
    { value: "Antigua-et-Barbuda", text: "Antigua-et-Barbuda" },
    { value: "Antilles néerlandaises", text: "Antilles néerlandaises" },
    { value: "Arabie saoudite", text: "Arabie saoudite" },
    { value: "Argentine", text: "Argentine" },
    { value: "Arménie", text: "Arménie" },
    { value: "Aruba", text: "Aruba" },
    { value: "Australie", text: "Australie" },
    { value: "Autriche", text: "Autriche" },
    { value: "Azerbaïdjan", text: "Azerbaïdjan" },
    { value: "Bahamas", text: "Bahamas" },
    { value: "Bahreïn", text: "Bahreïn" },
    { value: "Bangladesh", text: "Bangladesh" },
    { value: "Barbade", text: "Barbade" },
    { value: "Bélarus", text: "Bélarus" },
    { value: "Belgique", text: "Belgique" },
    { value: "Belize", text: "Belize" },
    { value: "Bénin", text: "Bénin" },
    { value: "Bermudes", text: "Bermudes" },
    { value: "Bhoutan", text: "Bhoutan" },
    { value: "Bolivie", text: "Bolivie" },
    { value: "Bosnie-Herzégovine", text: "Bosnie-Herzégovine" },
    { value: "Botswana", text: "Botswana" },
    { value: "Brésil", text: "Brésil" },
    { value: "Brunéi Darussalam", text: "Brunéi Darussalam" },
    { value: "Bulgarie", text: "Bulgarie" },
    { value: "Burkina Faso", text: "Burkina Faso" },
    { value: "Burundi", text: "Burundi" },
    { value: "Cambodge", text: "Cambodge" },
    { value: "Cameroun", text: "Cameroun" },
    { value: "Canada", text: "Canada" },
    { value: "Cap-Vert", text: "Cap-Vert" },
    { value: "Ceuta et Melilla", text: "Ceuta et Melilla" },
    { value: "Chili", text: "Chili" },
    { value: "Chine", text: "Chine" },
    { value: "Chypre", text: "Chypre" },
    { value: "Colombie", text: "Colombie" },
    { value: "Comores", text: "Comores" },
    { value: "Congo-Brazzaville", text: "Congo-Brazzaville" },
    { value: "Corée du Nord", text: "Corée du Nord" },
    { value: "Corée du Sud", text: "Corée du Sud" },
    { value: "Costa Rica", text: "Costa Rica" },
    { value: "Côte d’Ivoire", text: "Côte d’Ivoire" },
    { value: "Croatie", text: "Croatie" },
    { value: "Cuba", text: "Cuba" },
    { value: "Danemark", text: "Danemark" },
    { value: "Diego Garcia", text: "Diego Garcia" },
    { value: "Djibouti", text: "Djibouti" },
    { value: "Dominique", text: "Dominique" },
    { value: "Égypte", text: "Égypte" },
    { value: "El Salvador", text: "El Salvador" },
    { value: "Émirats arabes unis", text: "Émirats arabes unis" },
    { value: "Équateur", text: "Équateur" },
    { value: "Érythrée", text: "Érythrée" },
    { value: "Espagne", text: "Espagne" },
    { value: "Estonie", text: "Estonie" },
    { value: "État de la Cité du Vatican", text: "État de la Cité du Vatican" },
    { value: "États fédérés de Micronésie", text: "États fédérés de Micronésie" },
    { value: "États-Unis", text: "États-Unis" },
    { value: "Éthiopie", text: "Éthiopie" },
    { value: "Fidji", text: "Fidji" },
    { value: "Finlande", text: "Finlande" },
    { value: "France", text: "France" },
    { value: "Gabon", text: "Gabon" },
    { value: "Gambie", text: "Gambie" },
    { value: "Géorgie", text: "Géorgie" },
    { value: "Géorgie du Sud et les îles Sandwich du Sud", text: "Géorgie du Sud et les îles Sandwich du Sud" },
    { value: "Ghana", text: "Ghana" },
    { value: "Gibraltar", text: "Gibraltar" },
    { value: "Grèce", text: "Grèce" },
    { value: "Grenade", text: "Grenade" },
    { value: "Groenland", text: "Groenland" },
    { value: "Guadeloupe", text: "Guadeloupe" },
    { value: "Guam", text: "Guam" },
    { value: "Guatemala", text: "Guatemala" },
    { value: "Guernesey", text: "Guernesey" },
    { value: "Guinée", text: "Guinée" },
    { value: "Guinée équatoriale", text: "Guinée équatoriale" },
    { value: "Guinée-Bissau", text: "Guinée-Bissau" },
    { value: "Guyana", text: "Guyana" },
    { value: "Guyane française", text: "Guyane française" },
    { value: "Haïti", text: "Haïti" },
    { value: "Honduras", text: "Honduras" },
    { value: "Hongrie", text: "Hongrie" },
    { value: "Île Bouvet", text: "Île Bouvet" },
    { value: "Île Christmas", text: "Île Christmas" },
    { value: "Île Clipperton", text: "Île Clipperton" },
    { value: "Île de l'Ascension", text: "Île de l'Ascension" },
    { value: "Île de Man", text: "Île de Man" },
    { value: "Île Norfolk", text: "Île Norfolk" },
    { value: "Îles Åland", text: "Îles Åland" },
    { value: "Îles Caïmans", text: "Îles Caïmans" },
    { value: "Îles Canaries", text: "Îles Canaries" },
    { value: "Îles Cocos - Keeling", text: "Îles Cocos - Keeling" },
    { value: "Îles Cook", text: "Îles Cook" },
    { value: "Îles Féroé", text: "Îles Féroé" },
    { value: "Îles Heard et MacDonald", text: "Îles Heard et MacDonald" },
    { value: "Îles Malouines", text: "Îles Malouines" },
    { value: "Îles Mariannes du Nord", text: "Îles Mariannes du Nord" },
    { value: "Îles Marshall", text: "Îles Marshall" },
    { value: "Îles Mineures Éloignées des États-Unis", text: "Îles Mineures Éloignées des États-Unis" },
    { value: "Îles Salomon", text: "Îles Salomon" },
    { value: "Îles Turks et Caïques", text: "Îles Turks et Caïques" },
    { value: "Îles Vierges britanniques", text: "Îles Vierges britanniques" },
    { value: "Îles Vierges des États-Unis", text: "Îles Vierges des États-Unis" },
    { value: "Inde", text: "Inde" },
    { value: "Indonésie", text: "Indonésie" },
    { value: "Irak", text: "Irak" },
    { value: "Iran", text: "Iran" },
    { value: "Irlande", text: "Irlande" },
    { value: "Islande", text: "Islande" },
    { value: "Israël", text: "Israël" },
    { value: "Italie", text: "Italie" },
    { value: "Jamaïque", text: "Jamaïque" },
    { value: "Japon", text: "Japon" },
    { value: "Jersey", text: "Jersey" },
    { value: "Jordanie", text: "Jordanie" },
    { value: "Kazakhstan", text: "Kazakhstan" },
    { value: "Kenya", text: "Kenya" },
    { value: "Kirghizistan", text: "Kirghizistan" },
    { value: "Kiribati", text: "Kiribati" },
    { value: "Koweït", text: "Koweït" },
    { value: "Laos", text: "Laos" },
    { value: "Lesotho", text: "Lesotho" },
    { value: "Lettonie", text: "Lettonie" },
    { value: "Liban", text: "Liban" },
    { value: "Libéria", text: "Libéria" },
    { value: "Libye", text: "Libye" },
    { value: "Liechtenstein", text: "Liechtenstein" },
    { value: "Lituanie", text: "Lituanie" },
    { value: "Luxembourg", text: "Luxembourg" },
    { value: "Macédoine", text: "Macédoine" },
    { value: "Madagascar", text: "Madagascar" },
    { value: "Malaisie", text: "Malaisie" },
    { value: "Malawi", text: "Malawi" },
    { value: "Maldives", text: "Maldives" },
    { value: "Mali", text: "Mali" },
    { value: "Malte", text: "Malte" },
    { value: "Maroc", text: "Maroc" },
    { value: "Martinique", text: "Martinique" },
    { value: "Maurice", text: "Maurice" },
    { value: "Mauritanie", text: "Mauritanie" },
    { value: "Mayotte", text: "Mayotte" },
    { value: "Mexique", text: "Mexique" },
    { value: "Moldavie", text: "Moldavie" },
    { value: "Monaco", text: "Monaco" },
    { value: "Mongolie", text: "Mongolie" },
    { value: "Monténégro", text: "Monténégro" },
    { value: "Montserrat", text: "Montserrat" },
    { value: "Mozambique", text: "Mozambique" },
    { value: "Myanmar", text: "Myanmar" },
    { value: "Namibie", text: "Namibie" },
    { value: "Nauru", text: "Nauru" },
    { value: "Népal", text: "Népal" },
    { value: "Nicaragua", text: "Nicaragua" },
    { value: "Niger", text: "Niger" },
    { value: "Nigéria", text: "Nigéria" },
    { value: "Niue", text: "Niue" },
    { value: "Norvège", text: "Norvège" },
    { value: "Nouvelle-Calédonie", text: "Nouvelle-Calédonie" },
    { value: "Nouvelle-Zélande", text: "Nouvelle-Zélande" },
    { value: "Oman", text: "Oman" },
    { value: "Ouganda", text: "Ouganda" },
    { value: "Ouzbékistan", text: "Ouzbékistan" },
    { value: "Pakistan", text: "Pakistan" },
    { value: "Palaos", text: "Palaos" },
    { value: "Panama", text: "Panama" },
    { value: "Papouasie-Nouvelle-Guinée", text: "Papouasie-Nouvelle-Guinée" },
    { value: "Paraguay", text: "Paraguay" },
    { value: "Pays-Bas", text: "Pays-Bas" },
    { value: "Pérou", text: "Pérou" },
    { value: "Philippines", text: "Philippines" },
    { value: "Pitcairn", text: "Pitcairn" },
    { value: "Pologne", text: "Pologne" },
    { value: "Polynésie française", text: "Polynésie française" },
    { value: "Porto Rico", text: "Porto Rico" },
    { value: "Portugal", text: "Portugal" },
    { value: "Qatar", text: "Qatar" },
    { value: "R.A.S. chinoise de Hong Kong", text: "R.A.S. chinoise de Hong Kong" },
    { value: "R.A.S. chinoise de Macao", text: "R.A.S. chinoise de Macao" },
    { value: "régions éloignées de l’Océanie", text: "régions éloignées de l’Océanie" },
    { value: "République centrafricaine", text: "République centrafricaine" },
    { value: "République démocratique du Congo", text: "République démocratique du Congo" },
    { value: "République dominicaine", text: "République dominicaine" },
    { value: "République tchèque", text: "République tchèque" },
    { value: "Réunion", text: "Réunion" },
    { value: "Roumanie", text: "Roumanie" },
    { value: "Royaume-Uni", text: "Royaume-Uni" },
    { value: "Russie", text: "Russie" },
    { value: "Rwanda", text: "Rwanda" },
    { value: "Sahara occidental", text: "Sahara occidental" },
    { value: "Saint-Barthélémy", text: "Saint-Barthélémy" },
    { value: "Saint-Kitts-et-Nevis", text: "Saint-Kitts-et-Nevis" },
    { value: "Saint-Marin", text: "Saint-Marin" },
    { value: "Saint-Martin", text: "Saint-Martin" },
    { value: "Saint-Pierre-et-Miquelon", text: "Saint-Pierre-et-Miquelon" },
    { value: "Saint-Vincent-et-les Grenadines", text: "Saint-Vincent-et-les Grenadines" },
    { value: "Sainte-Hélène", text: "Sainte-Hélène" },
    { value: "Sainte-Lucie", text: "Sainte-Lucie" },
    { value: "Samoa", text: "Samoa" },
    { value: "Samoa américaines", text: "Samoa américaines" },
    { value: "Sao Tomé-et-Principe", text: "Sao Tomé-et-Principe" },
    { value: "Sénégal", text: "Sénégal" },
    { value: "Serbie", text: "Serbie" },
    { value: "Serbie-et-Monténégro", text: "Serbie-et-Monténégro" },
    { value: "Seychelles", text: "Seychelles" },
    { value: "Sierra Leone", text: "Sierra Leone" },
    { value: "Singapour", text: "Singapour" },
    { value: "Slovaquie", text: "Slovaquie" },
    { value: "Slovénie", text: "Slovénie" },
    { value: "Somalie", text: "Somalie" },
    { value: "Soudan", text: "Soudan" },
    { value: "Sri Lanka", text: "Sri Lanka" },
    { value: "Suède", text: "Suède" },
    { value: "Suisse", text: "Suisse" },
    { value: "Suriname", text: "Suriname" },
    { value: "Svalbard et Île Jan Mayen", text: "Svalbard et Île Jan Mayen" },
    { value: "Swaziland", text: "Swaziland" },
    { value: "Syrie", text: "Syrie" },
    { value: "Tadjikistan", text: "Tadjikistan" },
    { value: "Taïwan", text: "Taïwan" },
    { value: "Tanzanie", text: "Tanzanie" },
    { value: "Tchad", text: "Tchad" },
    { value: "Terres australes françaises", text: "Terres australes françaises" },
    { value: "Territoire britannique de l'océan Indien", text: "Territoire britannique de l'océan Indien" },
    { value: "Territoire palestinien", text: "Territoire palestinien" },
    { value: "Thaïlande", text: "Thaïlande" },
    { value: "Timor oriental", text: "Timor oriental" },
    { value: "Togo", text: "Togo" },
    { value: "Tokelau", text: "Tokelau" },
    { value: "Tonga", text: "Tonga" },
    { value: "Trinité-et-Tobago", text: "Trinité-et-Tobago" },
    { value: "Tristan da Cunha", text: "Tristan da Cunha" },
    { value: "Tunisie", text: "Tunisie" },
    { value: "Turkménistan", text: "Turkménistan" },
    { value: "Turquie", text: "Turquie" },
    { value: "Tuvalu", text: "Tuvalu" },
    { value: "Ukraine", text: "Ukraine" },
    { value: "Union européenne", text: "Union européenne" },
    { value: "Uruguay", text: "Uruguay" },
    { value: "Vanuatu", text: "Vanuatu" },
    { value: "Venezuela", text: "Venezuela" },
    { value: "Viet Nam", text: "Viet Nam" },
    { value: "Wallis-et-Futuna", text: "Wallis-et-Futuna" },
    { value: "Yémen", text: "Yémen" },
    { value: "Zambie", text: "Zambie" },
    { value: "Zimbabwe", text: "Zimbabwe" },
];
