
export const departments = [

    { value:"ALIBORI", text: "ALIBORI"},
    { value:"ATACORA", text: "ATACORA"},
    { value:"ATLANTIQUE", text: "ATLANTIQUE"},
    { value:"BORGOU", text: "BORGOU"},
    { value:"COLLINES", text: "COLLINES"},
    { value:"COUFFO", text: "COUFFO"},
    { value:"DONGA", text: "DONGA"},
    { value:"LITTORAL", text: "LITTORAL"},
    { value:"MONO", text: "MONO"},
    { value:"OUEME", text: "OUEME"},
    { value:"PLATEAU", text: "PLATEAU"},
    { value:"ZOU", text: "ZOU"},

];



