<template>
  <div class="row">
    <VeeForm
      class="col"
      v-slot="{ handleSubmit, meta }"
      :validation-schema="schema"
      as="div"
    >
      <div class="alert alert-danger d-none" id="alertError" role="alert"></div>
      <form
        @submit="handleSubmit($event, onSubmit)"
        class="row"
        id="generalityForm"
        ref="generalityForm"
      >
        <div class="col">
          <!-- //* Généralité -->
          <!-- <div class="alert alert-danger fs-5 mt-5 text-center" role="alert">
            Les candidatures sont closes.
          </div> -->
          <div class="row mb-2 mt-5">
            <div class="col mt-1">
              <h2 class="section-title text-uppercase">Généralités</h2>
            </div>
          </div>
          <div class="row">
            <!-- //? Civilité -->
            <div class="mb-4 col-lg-4 col-md-6 col-sm-12 col-12">
              <label for="first_name" class="form-label required">
                Civilité</label
              >
              <div class="form-check form-check">
                <Field
                  class="form-check-input"
                  type="radio"
                  name="radioGender"
                  id="radioMan"
                  value="M"
                  v-model="gender"
                />
                <label class="form-check-label" for="radioMan">
                  Monsieur
                </label>
              </div>
              <div class="form-check form-check">
                <Field
                  class="form-check-input"
                  type="radio"
                  name="radioGender"
                  id="radioWoman"
                  value="F"
                  v-model="gender"
                />
                <label class="form-check-label" for="radioWoman">
                  Madame
                </label>
              </div>
            </div>
            
            <!-- //? Nom -->
            <div class="mb-4 col-lg-4 col-md-6 col-sm-12 col-12">
              <label for="last_name" class="form-label required"> Nom </label>
              <Field
                name="last_name"
                type="text"
                label="nom"
                v-slot="{ field, errorMessage }"
                v-model="last_name"
              >
                <input
                  id="last_name"
                  v-bind="field"
                  placeholder="Saisissez un texte"
                  class="form-control"
                  :class="{ 'is-invalid': errorMessage }"
                />
              </Field>
              <ErrorMessage
                name="last_name"
                as="div"
                class="invalid-feedback"
              />
            </div>
            <!-- //? Prenom -->
            <div class="mb-4 col-lg-4 col-md-6 col-sm-12 col-12">
              <label for="first_name" class="form-label required">
                Prénom
              </label>
              <Field
                name="first_name"
                type="text"
                label="prénom"
                v-slot="{ field, errorMessage }"
                v-model="first_name"
              >
                <input
                  id="first_name"
                  v-bind="field"
                  placeholder="Saisissez un texte"
                  class="form-control"
                  :class="{ 'is-invalid': errorMessage }"
                />
              </Field>
              <ErrorMessage
                name="first_name"
                as="div"
                class="invalid-feedback"
              />
            </div>
            <!-- //? Date de naissance -->
            <div class="mb-4 col-lg-4 col-md-6 col-sm-12 col-12">
              <label for="birthdate" class="form-label required"
                >Date de naissance</label
              >
              <Field
                name="birthdate"
                label="date de naissance"
                v-slot="{ field, errorMessage }"
                v-model="birthdate"
              >
                <input
                  id="birthdate"
                  type="date"
                  v-bind="field"
                  class="form-control"
                  :class="{ 'is-invalid': errorMessage }"
                  max="2012-12-31"
                />
              </Field>
              <ErrorMessage
                name="birthdate"
                as="div"
                class="invalid-feedback"
              />
            </div>
            <!-- //? Pays d'origine -->
            <div class="mb-4 col-lg-4 col-md-6 col-sm-12 col-12">
              <label for="country" class="form-label required">Pays</label>
              <Field
                name="country"
                v-model="country"
                label="Pays d'origine"
                v-slot="{ field, errorMessage }"
              >
                <select
                  class="form-select"
                  id="country"
                  v-bind="field"
                  :class="{ 'is-invalid': errorMessage }"
                  aria-label="eeia candidature select"
                  @change="sendCountryChangeUpdate()"
                >
                  <option value="">Cliquer pour faire un choix</option>
                  <option
                    v-for="item in countries"
                    :key="item.value"
                    :value="item.value"
                  >
                    {{ item.text }}
                  </option>
                </select>
              </Field>
              <ErrorMessage name="country" as="div" class="invalid-feedback" />
            </div>
            
            <!-- //? Taille du t-shirt -->
            <div class="mb-4 col-lg-4 col-md-6 col-sm-12 col-12">
              <label for="size" class="form-label"
                >Taille du t-shirt</label
              ><label class="required"></label>
              <Field
                name="size"
                v-model="size"
                label="taille du t-shirt"
                v-slot="{ field, errorMessage }"
              >
                <select
                  class="form-select"
                  id="size"
                  v-bind="field"
                  :class="{ 'is-invalid': errorMessage }"
                  aria-describedby="sizeHelpBlock"
                >
                  <option value="">Faites un choix</option>
                  <option value="S">S</option>
                  <option value="M">M</option>
                  <option value="L">L</option>
                  <option value="XL">XL</option>
                  <option value="XL">XL</option>
                  <option value="XXL">XXL</option>
                </select>
                <div id="sizeHelpBlock" class="form-text">
                  La taille du t-shirt que vous portez habituellement.
                </div>
              </Field>
              <ErrorMessage name="size" as="div" class="invalid-feedback" />
            </div>
          </div>

          <!-- //* Personne de référence -->

          <div class="row mb-2">
            <div class="col mt-5">
              <h3 class="section-title">
                Personne de référence
                <!-- <font-awesome-icon icon="info-circle" class="ms-2 text-primary" data-bs-toggle="tooltip" data-bs-title="Personne de confiance pour attester de vos informations et à appeler en cas de difficultés à vous contacter."/> -->
              </h3>
            </div>
          </div>
          <div class="row">
            <div class="mb-4 col-lg-4 col-md-6 col-sm-12 col-12">
              <label for="referenceLastName" class="form-label required"
                >Nom</label
              >
              <Field
                name="referenceLastName"
                v-model="referenceLastName"
                type="text"
                label="nom"
                v-slot="{ field, errorMessage }"
              >
                <input
                  id="referenceLastName"
                  v-bind="field"
                  placeholder="Entrer le nom ici."
                  class="form-control"
                  :class="{ 'is-invalid': errorMessage }"
                />
              </Field>
              <ErrorMessage
                name="referenceLastName"
                as="div"
                class="invalid-feedback"
              />
            </div>
            <div class="mb-4 col-lg-4 col-md-6 col-sm-12 col-12">
              <label for="referenceFirstName" class="form-label">Prénom</label>
              <label class="required"></label>
              <Field
                name="referenceFirstName"
                type="text"
                v-model="referenceFirstName"
                label="prénom"
                v-slot="{ field, errorMessage }"
              >
                <input
                  id="referenceFirstName"
                  v-bind="field"
                  placeholder="Entrer le prénom ici."
                  class="form-control"
                  :class="{ 'is-invalid': errorMessage }"
                />
              </Field>
              <ErrorMessage
                name="referenceFirstName"
                as="div"
                class="invalid-feedback"
              />
            </div>
            <div class="mb-4 col-lg-4 col-md-6 col-sm-12 col-12">
              <label for="referencePhone" class="form-label">Téléphone</label>
              <label class="required"></label>
              <Field
                name="referencePhone"
                v-model="referencePhone"
                type="text"
                label="téléphone"
                v-slot="{ field, errorMessage }"
              >
                <input
                  id="referencePhone"
                  v-bind="field"
                  placeholder="Entrer le numéro de téléphone ici."
                  class="form-control"
                  :class="{ 'is-invalid': errorMessage }"
                />
                <ErrorMessage
                  name="referencePhone"
                  as="div"
                  class="invalid-feedback"
                  :class="{ 'd-block': errorMessage }"
                />
              </Field>
            </div>
            <div class="mb-4 col-lg-4 col-md-6 col-sm-12 col-12">
              <label for="referenceEmail" class="form-label">Email</label>
              <Field
                name="referenceEmail"
                v-model.trim="referenceEmail"
                type="email"
                label="email"
                v-slot="{ field, errorMessage }"
              >
                <input
                  id="referenceEmail"
                  v-bind="field"
                  placeholder="Entrer l'email ici."
                  class="form-control"
                  :class="{ 'is-invalid': errorMessage }"
                />
                <ErrorMessage
                  name="referenceEmail"
                  as="div"
                  class="invalid-feedback"
                  :class="{ 'd-block': errorMessage }"
                />
              </Field>
            </div>
          </div>

          <div class="row mb-4">
            <div>
              <button
                class="btn btn-primary mt-2 btn-lg"
                type="submit"
                id="submit-button"
              >
                <span v-if="step >= 3">Suivant</span>
                <span v-else>Suivant</span>
              </button>
            </div>
            <span class="text-danger d-none" id="form-invalid-error">
              <i v-if="!meta.valid">
                <!-- <img src="../assets/img/fail.svg" alt=""> -->
                Une ou plusieurs erreurs sont présentes dans le formulaire.
              </i>
            </span>
            <div class="mt-3 fst-italic">
              <span class="text-muted">
                <label class="required"></label> Obligatoire
              </span>
            </div>
          </div>
        </div>
      </form>
    </VeeForm>
  </div>
</template>

<script>
import { countries } from "./../countries";

import { Form as VeeForm, Field, ErrorMessage } from "vee-validate";
import { defineRule } from "vee-validate";
import { required } from "@vee-validate/rules";

import "intl-tel-input/build/css/intlTelInput.css";
import intlTelInput from "intl-tel-input";
import parsePhoneNumber from "libphonenumber-js";
var iti;

import { setLocale } from "@vee-validate/i18n";
import { configure } from "vee-validate";
import { localize } from "@vee-validate/i18n";
import fr from "@vee-validate/i18n/dist/locale/fr.json";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(faInfoCircle);

// const bootstrap = require('bootstrap')

configure({
  generateMessage: localize({
    fr,
  }),
});
setLocale("fr");

defineRule("required", required);
defineRule("telephone", (value) => {
  // Field is empty, should pass
  if (!value || !value.length) {
    return true;
  }
  // Check if international telephone numbers is valid
  if (!iti.isValidNumber()) {
    return "Le numéro de téléphone n'est pas valide.";
  }
  return true;
});

export default {
  name: "Generality",
  inject: [
    "ToastSuccess",
    "ToastError",
    "currentYearUrl",
    "AlertWarning",
    "AlertSuccess",
    "AlertError",
  ],
  components: {
    // Rename form to avoid confusion with native `form`
    VeeForm,
    Field,
    ErrorMessage,
    // FontAwesomeIcon,
  },
  props: {
    previousData: Object,
    step: Number,
    lastName: String,
    firstName: String,
  },
  mounted() {
    let input = document.querySelector("#referencePhone");
    iti = intlTelInput(input, {
      preferredCountries: ["bj", "bf", "ci", "dj", "ne", "sn", "tg", "ga"],
      initialCountry: "bj",
      utilsScript:
        "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.19/js/utils.min.js",
    });

    let that = this;
    const phoneNumber = parsePhoneNumber(this.referencePhone);
    let appIsAlreadyMounted = false;

    document.querySelector(".iti").addEventListener("click", function () {
      if (!appIsAlreadyMounted) {
        appIsAlreadyMounted = !appIsAlreadyMounted;
        // console.log(phoneNumber.formatNational())
        that.referencePhone = phoneNumber.formatNational();
      }
    });

    document
      .getElementById("submit-button")
      .addEventListener("click", function () {
        document
          .getElementById("form-invalid-error")
          .classList.remove("d-none");
      });

      // const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
      // // eslint-disable-next-line no-unused-vars
      // const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))
  },
  data() {
    const schema = {
      birthdate: "required",
      first_name: "required",
      last_name: "required",
      referenceLastName: "required",
      referenceFirstName: "required",
      referencePhone: "required|telephone",
      referenceEmail: "custom_email",
      radioGender: "required",
      size: "required",
    };

    return {
      schema,
      birthdate: this.previousData.birthdate,
      last_name: this.lastName,
      first_name: this.firstName,
      referenceLastName: this.previousData.referenceLastName,
      referenceFirstName: this.previousData.referenceFirstName,
      referencePhone: this.previousData.referencePhone,
      referenceEmail: this.previousData.referenceEmail,
      country: this.previousData.country,
      countries: countries,
      gender: this.previousData.gender == "" ? "M" : this.previousData.gender,
      size: this.previousData.size,
    };
  },
  methods: {
    onSubmit(values, actions) {
      this.beforeSendPromise(values, this).then((formData) => {
        this.$http
          .post("new-candidacies", formData)

          .then(() => {
            this.$emit("to-next");
          })
          .catch((error) => {
            if (error.response.status == 422) {
              this.ToastError.fire({
                title: "Veuillez bien remplir le formulaire et réessayez.",
              });

              let errors = error.response.data.errors;
              for (const key in errors) {
                if (Object.prototype.hasOwnProperty.call(errors, key)) {
                  const element = errors[key];
                  actions.setFieldError(key, element);
                }
              }
            } else if (error.response.status == 403) {
              this.AlertError.fire({
                icon: "error",
                title: "Non autorisé",
                html: "Vous ne pouvez plus modifier votre candidature.",
              });
            } else {
              this.ToastError.fire({
                title: "Une erreur s'est produite, veuillez réessayer.",
              });
            }
          });
      });
    },
    beforeSendPromise(values, $this) {
      return new Promise(function (resolve) {
        values.step = 1;
        let formData = new FormData();
        formData.append("step", 1);

        formData.append("referenceLastName", $this.referenceLastName);
        formData.append("referenceFirstName", $this.referenceFirstName);
        formData.append("referencePhone", iti.getNumber());
        formData.append("referenceEmail", $this.referenceEmail);
        formData.append("birthdate", $this.birthdate);
        formData.append("last_name", $this.last_name);
        formData.append("first_name", $this.first_name);
        formData.append("country", $this.country);
        formData.append("gender", $this.gender);
        formData.append("first_name", $this.first_name);
        formData.append("last_name", $this.last_name);
        formData.append("size", $this.size);

        resolve(formData);
      });
    },
    sendCountryChangeUpdate() {
      this.$emit("native-country-change", this.country);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media (min-width: 768px) {
  .form-control,
  .form-select {
    line-height: 2;
  }
}

.section-title {
  border-bottom: 3px solid #3490dc;
}
</style>
