<template>
  <div class="row">
    <VeeForm
      class="col"
      v-slot="{ handleSubmit, meta }"
      :validation-schema="schema"
      as="div"
    >
      <div class="alert alert-danger d-none" id="alertError" role="alert"></div>
      <div
        class="alert alert-danger d-none mt-3 w-75 position-sticky"
        id="submitFileError"
        role="alert"
      >
        Votre dossier est incomplet. Vous devez le compléter et le soumettre
        sinon votre candidature ne sera pas prise en compte.
      </div>
      <form
        @submit="handleSubmit($event, onSubmit)"
        class="row"
        id="generalityForm"
        ref="generalityForm"
      >
        <div class="col">
          <!-- //* Pièces jointes -->

          <div class="row">
            <div class="col mt-4">
              <h2 class="section-title text-uppercase">Pièces jointes</h2>
            </div>
          </div>

          <div class="row mt-3 mb-2">
            <div class="col">
              <div class="alert alert-info" role="alert">
                <h5 class="modal-title">Liste des pièces à fournir :</h5>
                <ul>
                  <li>
                    La lettre de motivation (en PDF) adressée au comité pédagogique
                    <span class="text-danger">(obligatoire)</span>
                  </li>
                  <li>
                    La pièce d'identité / la carte scolaire / le certificat de
                    scolarité <span class="text-danger">(obligatoire)</span>
                  </li>
                  <li>
                    Les deux derniers bulletins
                    <span class="text-danger"
                      >(obligatoire pour les <b>élèves</b>)</span
                    >
                  </li>
                  <li>
                    La lettre de recommandation du Responsable d’école / du
                    professeur des Mathématiques / du professeur de PCT
                    <span class="text-danger">(facultative)</span>
                  </li>
                  <li>
                    L' autorisation parentale à remplir par le parent pour le
                    compte de l’apprenant mineur
                    <span class="text-danger"
                      >(obligatoire pour les mineurs)</span
                    >
                  </li>
                  <li>
                    Curriculum Vitae
                    <span class="text-danger"
                      >(obligatoire à partir de ceux qui ont déjà le BAC et les
                      professionnels)</span
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <!-- <div class="row mt-3 mb-2 text-center">
            <div class="col">
              <button
                type="button"
                class="btn btn-outline-primary"
                data-bs-toggle="modal"
                data-bs-target="#pupilsModal"
              >
                Règles de constitution des pièces jointes.
              </button>
            </div>
          </div> -->
          <div class="row mb-1 text-center">
            <div class="col">
              <!-- Button trigger modal -->
              <button
                type="button"
                class="btn btn-primary btn-lg"
                data-bs-toggle="modal"
                data-bs-target="#attachmentModal"
              >
                Cliquer ici pour ajouter une pièce
              </button>
            </div>
          </div>
          <div class="row px-md-4">
            <!-- Table -->
            <div class="table-responsive bg-light my-4">
              <h5 class="text-center text-primary mt-3">
                <u>Liste des pièces choisies</u>
              </h5>
              <table class="table table-bordered mt-3">
                <thead>
                  <tr>
                    <th scope="col">Type/Intitulé</th>
                    <th scope="col" style="width: 25%">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-if="attachmentsDatatable.length <= 0"
                    class="text-center"
                  >
                    <td colspan="2">
                      <div class="alert alert-info mt-2" role="alert">
                        Aucune pièce choisie!
                      </div>
                    </td>
                  </tr>
                  <tr
                    v-for="item in attachmentsDatatable"
                    :key="item.nameValue"
                  >
                    <td>{{ item.nameText }}</td>
                    <td class="text-center">
                      <div
                        class="btn-group"
                        role="group"
                        aria-label="Button group"
                      >
                        <a
                          class="btn btn-primary text-white"
                          href="#"
                          @click.prevent="showFile(item.uploadCallback.path)"
                          role="button"
                          >Voir
                          <font-awesome-icon
                            icon="external-link-alt"
                            class="ms-2"
                          />
                        </a>
                        <button
                          type="button"
                          :data-attachment="item.nameValue"
                          class="btn btn-danger text-white"
                          @click="removeAttachment(item.nameValue)"
                        >
                          Supprimer
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div v-if="attachmentsDatatable.length > 0" class="alert alert-info mt-2 submit-warn" role="alert">
            Avant de soumettre votre dossier, nous vous recommandons de <a href="">rafraîchir</a> le site et de vérifier vos informations à chaque étape.
          </div>

          <div class="text-center text-md-start">
            <button
              class="btn btn-lg btn-primary"
              @click="$emit('to-previous')"
              type="button"
            >
              Précédent
            </button>
            <button
              class="btn btn-lg btn-primary ms-sm-2 mt-2 mt-sm-0"
              id="submit-button"
              type="submit"
            >
              Soumettre mon dossier
            </button>
          </div>

          <div class="row mb-4">
            <span class="text-danger d-none" id="form-invalid-error"
              ><i v-if="!meta.valid">
                Une ou plusieurs erreurs sont présentes dans le formulaire.</i
              ></span
            >
            <div class="mt-3 fst-italic">
              <span class="text-muted">
                <label class="required"></label> Obligatoire
              </span>
            </div>
          </div>
        </div>
      </form>
    </VeeForm>

    <!-- Modal -->
    <div
      class="modal fade"
      id="pupilsModal"
      tabindex="-1"
      aria-labelledby="pupilsModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="pupilsModalLabel">
              Règles de constitution des pièces jointes.
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <ul>
              <li>
                La lettre de motivation adressée à la coordonnatrice du projet
                <span class="text-danger">(obligatoire)</span>
              </li>
              <li>
                La pièce d'identité / la carte scolaire / le certificat de
                scolarité <span class="text-danger">(obligatoire)</span>
              </li>
              <li>
                Les deux derniers bulletins
                <span class="text-danger"
                  >(obligatoire pour les <b>élèves</b>)</span
                >
              </li>
              <li>
                La lettre de recommandation du Responsable d’école / du
                professeur des Mathématiques / du professeur de PCT
                <span class="text-danger">(facultative)</span>
              </li>
              <li>
                L' autorisation parentale à remplir par le parent pour le compte
                de l’apprenant mineur
                <span class="text-danger">(obligatoire pour les mineurs)</span>
              </li>
            </ul>
          </div>
          <!-- <div class="modal-body" v-else>
              <ul>
                <li>Lettre de motivation (1Mo au plus)</li>
              </ul>
            </div> -->
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Fermer
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Pièces -->
    <div
      class="modal fade"
      id="attachmentModal"
      tabindex="-1"
      aria-labelledby="attachmentModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="attachmentModalLabel">
              Ajouter une pièce.
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <form
              @submit.prevent="onAttachmentsSubmit"
              id="attachmentForm"
              ref="attachmentForm"
            >
              <div class="mb-4">
                <label for="attachmentType" class="form-label"
                  >Type de pièce :</label
                >
                <select
                  class="form-select"
                  id="attachmentType"
                  v-model="attachmentType"
                  aria-label="eeia candidature select"
                >
                  <option value="">Cliquer pour faire un choix</option>
                  <option
                    v-for="item in attachmentTypes"
                    :key="item.name"
                    :value="item.name"
                    :class="{ 'd-none': item.disabled }"
                  >
                    {{ attachmentTexts[item.name] }}
                  </option>
                </select>
                <div class="invalid-feedback">
                  Le champ type de pièce est obligatoire
                </div>
              </div>
              <div class="mb-4">
                <label for="attachment" class="form-label"
                  >Sélectionner un fichier :</label
                >
                <input
                  class="form-control"
                  id="attachment"
                  type="file"
                  :accept="[
                    attachmentType == 'lettre-motivation'
                      ? 'application/pdf'
                      : 'application/pdf, image/png, image/jpeg, image/jpg',
                  ]"
                  aria-describedby="fileHelpBlock"
                  ref="attachment"
                />
                <div id="fileHelpBlock" class="form-text">
                  <span v-if="attachmentType != 'lettre-motivation'">
                    Seulement les fichiers <b>pdf, jpeg, jpg et png</b> sont
                    acceptés. La taille du fichier ne doit pas dépasser les
                    <b>5 Mo</b>.
                  </span>
                  <span v-else>
                    Seulement les fichiers en <b>pdf</b> sont acceptés. La
                    taille du fichier ne doit pas dépasser les <b>5 Mo</b>.
                  </span>
                </div>
                <div class="invalid-feedback">
                  Le champ fichier est obligatoire
                </div>
              </div>
              <button
                type="submit"
                class="btn btn-primary d-none"
                id="submitAttachmentFormButton"
              >
                Soumettre
              </button>
            </form>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Fermer
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click.prevent="submitAttachmentForm()"
            >
              Soumettre
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Form as VeeForm } from "vee-validate";
import { defineRule } from "vee-validate";
import { required } from "@vee-validate/rules";
import { Modal } from "bootstrap";

defineRule("required", required);

import { setLocale } from "@vee-validate/i18n";
import { configure } from "vee-validate";
import { localize } from "@vee-validate/i18n";
import fr from "@vee-validate/i18n/dist/locale/fr.json";
configure({
  generateMessage: localize({
    fr,
  }),
});
setLocale("fr");

import { library } from "@fortawesome/fontawesome-svg-core";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(faInfoCircle, faExternalLinkAlt);

// const droitImageDocument = new URL('/docs/autorisation-droit-a-l-image-modele.docx').href;

export default {
  inject: [
    "ToastSuccess",
    "ToastError",
    "currentYearUrl",
    "AlertWarning",
    "AlertSuccess",
    "AlertError",
    "ToastWarning",
  ],
  components: {
    // Rename form to avoid confusion with native `form`
    VeeForm,
    FontAwesomeIcon,
  },
  props: {
    previousData: Object,
    step: Number,
    previousAttachments: Object,
    stepTwoCategory: String,
  },
  mounted() {
    // document.getElementById('droitImageDoc').href = droitImageDocument;

    this.formatDataTable(this.previousAttachments.attachments);
    // localStorage.setItem("currentStep", 6);
    this.attachmentModal = new Modal(
      document.getElementById("attachmentModal"),
      {
        keyboard: false,
      }
    );

    let that = this;

    document
      .getElementById("attachmentModal")
      .addEventListener("hidden.bs.modal", function () {
        that.resetAttachmentForm();
      });

    document
      .getElementById("submit-button")
      .addEventListener("click", function () {
        document
          .getElementById("form-invalid-error")
          .classList.remove("d-none");
      });
  },
  data() {
    const schema = {
      tipota: "",
    };

    const attachmentsSchema = {
      attachmentType: "required",
      attachment: "required",
    };

    let attachmentTypes = [
      { name: "bulletin-1", disabled: false },
      { name: "bulletin-2", disabled: false },
      { name: "piece-identite", disabled: false },
      { name: "lettre-motivation", disabled: false },
      { name: "recommandation-professeur", disabled: false },
      { name: "autorisation-parentale", disabled: false },
      { name: "curriculum-vitae", disabled: false },
    ];
    let attachmentTexts = {
      "bulletin-1": "1er bulletin",
      "bulletin-2": "2ème bulletin",
      "piece-identite": "Pièce d'identité",
      "lettre-motivation": "Lettre de motivation",
      "recommandation-professeur":
        "Recommandation du professeur de mathématiques ou sciences physiques",
      "autorisation-parentale": "Autorisation parentale pour les mineurs",
      "curriculum-vitae": "Curriculum Vitae",
    };

    let attachmentsDatatable = [];

    return {
      schema,
      attachmentsSchema,
      attachmentType: "",
      attachmentTypes: attachmentTypes,
      attachmentTexts: attachmentTexts,
      attachmentsDatatable: attachmentsDatatable,
    };
  },
  methods: {
    formatDataTable(attachments) {
      attachments.forEach((attachment) => {
        let path = Object.prototype.hasOwnProperty.call(attachment, "path")
          ? attachment.path
          : "";

        this.attachmentsDatatable.push({
          nameValue: attachment.attachment_type,
          nameText: this.attachmentTexts[attachment.attachment_type],
          uploadCallback: {
            path: path,
          },
        });

        this.attachmentTypes.forEach((element, index) => {
          if (attachment.attachment_type == element.name) {
            this.attachmentTypes[index].disabled = true;
          }
        });
      });
    },
    onSubmit(values, actions) {
      // let $this = this
      // let promiseValues = new Promise(function (resolve) {
      //   console.log($this.$refs)
      //   values.step = 1;
      //   resolve(values);
      // });

      this.beforeSendPromise(values, this).then((formData) => {
        this.$http
          .post("new-candidacies", formData)

          .then(() => {
            this.AlertSuccess.fire({
              title: '',
              text: "Votre dossier de candidature a été envoyé avec succès !",
            });
            localStorage.setItem("currentStep", 1);
            // this.$emit("to-previous");
            window.scrollTo(0, 0);
            this.$emit("evaluation-sent");
          })
          .catch((error) => {
            if (error.response.status == 422) {
              this.ToastError.fire({
                title: "Veuillez bien remplir le formulaire et réessayez.",
              });

              let errors = error.response.data.errors;
              for (const key in errors) {
                if (Object.prototype.hasOwnProperty.call(errors, key)) {
                  const element = errors[key];
                  actions.setFieldError(key, element);
                }
              }
            } else if (error.response.status == 403) {
              this.AlertError.fire({
                icon: "error",
                title: "Non autorisé",
                html: "Vous ne pouvez plus modifier votre candidature.",
              });
            } else {
              this.ToastError.fire({
                title: "Une erreur s'est produite, veuillez réessayer.",
              });
            }
          });
      });
    },
    beforeSendPromise(values, $this) {
      return new Promise(function (resolve, reject) {
        if ($this.validateAttachmentsDatatable(true)) {
          values.step = 6;
          // values.attachments = $this.$refs.attachments.files.item(0);
          let formData = new FormData();

          formData.append("step", 6);

          formData.append("tipota", $this.tipota);

          //
          $this.AlertWarning.fire({
            title: "Confirmation !",
            html: `
              Souhaitez-vous définitivement soumettre votre dossier de candidature ?
              <br>
              Une fois soumis, <b>il ne sera plus modifiable</b>.`,
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Oui, soumettre",
            cancelButtonText: "Annuler",
          }).then((result) => {
            if (result.isConfirmed) {
              resolve(formData);
            } else {
              reject();
            }
          });
          //

        } else {
          reject();
        }
      });
    },
    validateAttachmentsDatatable(makeAlert) {
      let foundPieces = 0;
      let foundParentale = 0;
      let foundBulletin = 0;
      let foundPieceIdentite = 0;
      let foundCurriculum = 0;
      // let foundLettreMotivation = 0;

      this.attachmentsDatatable.forEach((attachment) => {
        if (attachment.nameValue == "piece-identite") {
          foundPieces++;
          foundPieceIdentite++;
        }
        if (attachment.nameValue == "lettre-motivation") {
          foundPieces++;
          // foundLettreMotivation++;
        }
        if (attachment.nameValue == "autorisation-parentale") {
          foundParentale++;
        }
        if (attachment.nameValue == "bulletin-1") {
          foundBulletin++;
        }
        if (attachment.nameValue == "bulletin-2") {
          foundBulletin++;
        }
        if (attachment.nameValue == "curriculum-vitae") {
          foundCurriculum++;
        }
      });

      //calculate month difference from current date in time
      let month_diff = Date.now() - new Date(this.birthdate);

      //convert the calculated difference in date format
      let age_dt = new Date(month_diff);

      //extract year from date
      let year = age_dt.getUTCFullYear();

      //now calculate the age of the user
      let age = Math.abs(year - 1970);
      let isMinor = age < 18;

      if (foundPieces == 2) {
        let errors = 0;

        if (isMinor && foundParentale != 1) {
          if (makeAlert) {
            this.AlertError.fire({
              icon: "error",
              title: "Pièce manquante",
              html: "L'<b>autorisation parentale</b> est obligatoire pour les mineurs.",
            });
          } else {
            document
              .getElementById("submitFileError")
              .classList.remove("d-none");
          }
          errors++;
        }

        if (foundBulletin != 2 && this.stepTwoCategory == "Elève") {
          if (makeAlert) {
            this.AlertError.fire({
              icon: "error",
              title: "Pièces manquantes",
              html: "Les <b>deux derniers bulletins</b> sont obligatoires pour les élèves.",
            });
          } else {
            document
              .getElementById("submitFileError")
              .classList.remove("d-none");
          }
          errors++;
        }

        if (foundCurriculum == 0 && this.stepTwoCategory != "Elève") {
          this.AlertError.fire({
            icon: "error",
            title: "Pièce manquante",
            html: "Le <b>Curriculum Vitae</b> est obligatoire à partir de ceux qui ont déjà le BAC et les professionnels.",
          });
          errors++;
        }

        if (errors > 0) {
          return false;
        } else {
          return true;
        }
      } else {
        
        if (foundPieceIdentite > 0) {
          if (makeAlert) {
            this.AlertError.fire({
              icon: "error",
              title: "Pièce manquante",
              html: "La <b>Lettre de motivation</b> est obligatoire.",
            });
          } else {
            document
              .getElementById("submitFileError")
              .classList.remove("d-none");
          }
          return false;
        } else {
          if (makeAlert) {
            this.AlertError.fire({
              icon: "error",
              title: "Pièce manquante",
              html: "La <b>Pièce d'identité</b> est obligatoire.",
            });
          } else {
            document
              .getElementById("submitFileError")
              .classList.remove("d-none");
          }
          return false;
        }
      }
    },
    submitAttachmentForm() {
      document.getElementById("submitAttachmentFormButton").click();
    },
    onAttachmentsSubmit() {
      if (this.validateAttachmentsForm() == true) {
        let formData = new FormData();
        formData.append("attachment_type", this.attachmentType);
        formData.append("country", this.country);
        formData.append("attachment", this.$refs.attachment.files.item(0));

        this.$http
          .post("attachment/store", formData)
          .then((response) => {
            this.ToastSuccess.fire({
              title: "Pièce envoyé avec succès",
            });
            this.addAttachmentToDataTable(response.data);
            this.attachmentModal.hide();
          })
          .catch((error) => {
            if (
              Object.prototype.hasOwnProperty.call(error.response, "status") &&
              error.response.status == 422
            ) {
              if (
                Object.prototype.hasOwnProperty.call(
                  error.response.data,
                  "errors"
                ) &&
                Object.prototype.hasOwnProperty.call(
                  error.response.data.errors,
                  "attachment"
                )
              ) {
                this.ToastError.fire({
                  title: error.response.data.errors.attachment[0],
                  // "La taille du fichier ne peut pas dépasser 1Mo. Veuillez bien remplir le formulaire et réessayer.",
                });
              } else {
                this.ToastError.fire({
                  title: "Veuillez bien remplir le formulaire et réessayez.",
                });
              }

              let errors = error.response.data.errors;
              for (const key in errors) {
                if (Object.prototype.hasOwnProperty.call(errors, key)) {
                  const element = errors[key];
                  if (key == "attachment_type") {
                    document
                      .getElementById("attachmentType")
                      .classList.add("is-invalid");
                    document.getElementById(
                      "attachmentType"
                    ).nextSibling.textContent = element;
                  } else {
                    document
                      .getElementById("attachmentType")
                      .classList.remove("is-invalid");
                    document.getElementById(
                      "attachmentType"
                    ).nextSibling.textContent =
                      "Le champ fichier est obligatoire";
                  }

                  if (key == "attachment") {
                    document
                      .getElementById("attachment")
                      .classList.add("is-invalid");
                    document.getElementById(
                      "fileHelpBlock"
                    ).nextSibling.textContent = element;
                  } else {
                    document
                      .getElementById("attachment")
                      .classList.remove("is-invalid");
                    document.getElementById(
                      "fileHelpBlock"
                    ).nextSibling.textContent = "";
                  }
                }
              }
            } else if (
              Object.prototype.hasOwnProperty.call(error.response, "status") &&
              error.response.status == 403
            ) {
              this.AlertError.fire({
                icon: "error",
                title: "Non autorisé",
                html: "Vous ne pouvez plus modifier votre candidature.",
              });
            } else {
              this.ToastError.fire({
                title: "Une erreur s'est produite, veuillez réessayer.",
              });
            }
          });
      }
    },
    validateAttachmentsForm() {
      let attachmentTypeInput = document.getElementById("attachmentType");
      let attachmentInput = document.getElementById("attachment");

      let errors = 0;

      if (attachmentTypeInput.value === "") {
        attachmentTypeInput.classList.add("is-invalid");
        errors++;
      } else {
        attachmentTypeInput.classList.remove("is-invalid");
      }

      if (this.$refs.attachment.files.length == 0) {
        attachmentInput.classList.add("is-invalid");
        errors++;
      } else {
        attachmentInput.classList.remove("is-invalid");
      }

      if (errors > 0) {
        return false;
      } else {
        return true;
      }
    },
    addAttachmentToDataTable(uploadCallback) {
      this.attachmentsDatatable.push({
        nameValue: this.attachmentType,
        nameText: this.attachmentTexts[this.attachmentType],
        uploadCallback: uploadCallback,
      });

      this.attachmentTypes.forEach((element, index) => {
        if (element.name == this.attachmentType) {
          this.attachmentTypes[index].disabled = true;
        }
      });
    },
    resetAttachmentForm() {
      // Resetting Values
      this.attachmentType = "";
      this.$refs.attachment.value = "";

      // Wait until the models are updated in the UI
      this.$nextTick(() => {
        // this.$refs.attachmentForm.reset();
      });
    },
    removeAttachment(attachmentType) {
      this.AlertWarning.fire({
        title: "Attention!",
        text: "Voulez-vous vraiment supprimer cette pièce ?",
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui, supprimer!",
        cancelButtonText: "Annuler",
      }).then((result) => {
        if (result.isConfirmed) {
          let _this = this;
          this.deleteFiles(attachmentType, _this);
        }
      });
    },
    deleteFiles(attachmentType, _this) {
      let path;
      this.attachmentsDatatable.forEach((element, index) => {
        if (element.nameValue == attachmentType) {
          path = this.attachmentsDatatable[index].uploadCallback.path;
        }
      });

      let formData = new FormData();
      formData.append("path", path);

      this.$http
        .post("/attachment/delete", formData)
        .then(() => {
          try {
            this.attachmentTypes.forEach((element, index) => {
              if (element.name == attachmentType) {
                this.attachmentTypes[index].disabled = false;
              }
            });

            this.attachmentsDatatable.forEach((element, index) => {
              if (element.nameValue == attachmentType) {
                this.attachmentsDatatable.splice(index, 1);
              }
            });

            if (_this.step == 6) {
              this.validateAttachmentsDatatable(false);
              this.ToastSuccess.fire({
                title: "Pièce supprimée !",
                text: "N'oubliez pas de soumettre votre dossier.",
              });
            } else {
              this.ToastSuccess.fire({
                title: "Pièce supprimée !",
              });
              document
                .getElementById("submitFileError")
                .classList.add("d-none");
            }
          } catch (error) {
            // console.log(error);
          }
        })
        .catch(function (error) {
          if (error.response.status == 403) {
            _this.AlertError.fire({
              icon: "error",
              title: "Non autorisé",
              html: "Vous ne pouvez plus modifier votre candidature.",
            });
          } else {
            _this.AlertError.fire({
              icon: "error",
              title: "Echec !",
              text: "Désolé, une erreur s'est produite. Veuillez réessayer.",
            });
          }
        });
    },
    showFile(filename) {
      var windowReference = window.open();
      this.$http({
        url: "attachment/show",
        method: "GET",
        params: {
          filename: filename,
        },
        responseType: "blob",
      })
        .then((response) => {
          // const file = new Blob([response.data], { type: "application/pdf" });
          // const fileUrl = URL.createObjectURL(file);
          // windowReference.location.assign(fileUrl);
          //!
          const fileContent = response.data;
          this.$http({
            url: "attachment/get-mime-type",
            method: "GET",
            params: {
              filename: filename,
            },
          })
            .then((response) => {
              // console.log(response.data);
              const file = new Blob([fileContent], {
                type: response.data,
              });
              const fileUrl = URL.createObjectURL(file);
              windowReference.location.assign(fileUrl);
            })
            .catch(function (error) {
              if (error.response) {
                this.ToastError.fire({
                  icon: "error",
                  title: "Echec !",
                  text: "Désolé, une erreur s'est produite. Veuillez réessayer.",
                });
              }
            });
          //!
        })
        .catch(function (error) {
          if (error.response) {
            this.ToastError.fire({
              icon: "error",
              title: "Echec !",
              text: "Désolé, une erreur s'est produite. Veuillez réessayer.",
            });
          }
        });
    },
    categoryChanged() {
      this.level = "";
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media (min-width: 768px) {
  .form-control,
  .form-select {
    line-height: 2;
  }
}

.section-title {
  border-bottom: 3px solid #3490dc;
}

#submitFileError {
  z-index: 5;
  top: 15px;
  left: 25% !important;
}

.submit-warn {
  font-size: 14px !important;
  padding: 10px !important;
}
</style>
