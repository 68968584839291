<template>
  <div class="form-wrapper">
    <main class="form-signin shadow-lg">
      <div class="text-center px-4">
        <div>
          <a href="/">
            <img src="../assets/img/eeia-logo.png" alt="" class="img-fluid" />
          </a>
        </div>
      </div>
      <!-- <h1 class="h2 mt-4 mb-3 text-center">
        École d'Été sur l'Intélligence Artificielle (EEIA)
      </h1> -->

      <h3 class="h3 mb-4 mt-4 text-center">
        <u>Conditions générales d'utilisation</u>
      </h3>

      <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="col">
            <h5>ARTICLE 1. INFORMATIONS LÉGALES</h5>
            <p>
              En vertu de l'article 6 de la Loi n° 2004-575 du 21 juin 2004 pour
              la confiance dans l'économie numérique, il est précisé dans cet
              article l'identité des différents intervenants dans le cadre de sa
              réalisation et de son suivi.
            </p>
            <p>Le site EEIA est édité par&nbsp;:</p>
            <p>
              L’association Bénin Excellence dont le siège social est situé à
              l'adresse suivante : Carré 837 C – Quartier Aidjèdo
            </p>
            <p>
              Téléphone&nbsp;:
              <span id="span_id_n_de_telephone_de_la_societe"
                >+229 91 05 15 15 / 91 13 87 87</span
              >/ Adresse e-mail&nbsp;:
              <span id="span_id_adresse_mail_editeur"
                ><a href="mailto:eeia@beninexcellence.org"
                  >eeia@beninexcellence.org</a
                ></span
              >.
            </p>
            <p><br />Le site EEIA est hébergé par&nbsp;:</p>
            <p>
              <span id="span_id_denomination_sociale_de_l_hebergeur">LWS</span>,
              dont le siège est situé à l'adresse ci-après&nbsp;:
            </p>
            <p style="padding-left: 30px">
              <span id="span_id_siege_social_de_l_hebergeur"
                >10, RUE PENTHIEVRE 75008 PARIS FRANCE</span
              >
            </p>
            <p>Numéro de téléphone&nbsp;: +33 177 62 30 03</p>

            <h5>ARTICLE 2. PRÉSENTATION DU SITE</h5>
            <p>Le site EEIA a pour objet&nbsp;:</p>
            <p style="padding-left: 30px">
              <span id="span_id_description_personnalisee_du_site"
                >De permettre à tous ceux qui sont passionnés par l'Intelligence
                Artificielle de candidater pour l’EEIA (Ecole d’été sur
                l’intelligence artificielle). L’EEIA est une formation à 75% pratique
                 qui s’étend sur une durée de 4 semaines dans les vacances.
                Cette formation a pour principal objet l’initiation au domaine
                de l’Intelligence Artificielle.</span
              >
            </p>

            <h5>ARTICLE 3. CONTACT</h5>

            <p>
              Pour toute question ou demande d'information concernant le site,
              ou tout signalement de contenu ou d'activités illicites,
              l'utilisateur peut contacter l'éditeur à l'adresse e-mail
              suivante:
              <span id="span_id_adresse_e_mail_de_contact_pour_le_dp"
                ><a href="mailto:eeia@beninexcellence.org"
                  >eeia@beninexcellence.org</a
                ></span
              >
              ou adresser un courrier recommandé avec accusé de réception
              à&nbsp;:
              <span id="span_id_nom_editeur"
                >L'association Bénin Excellence</span
              >
              -
              <span id="span_id_adresse_siege_social_editeur"
                >BP 636 Abomey Calavi</span
              >
            </p>

            <h5>ARTICLE 4. ACCEPTATION DES CONDITIONS D'UTILISATION</h5>
            <p>
              L'accès et l'utilisation du site sont soumis à l'acceptation et au
              respect des présentes Conditions Générales d'Utilisation.
            </p>
            <p>
              L'éditeur se réserve le droit de modifier, à tout moment et sans
              préavis, le site et des services ainsi que les présentes CGU,
              notamment pour s'adapter aux évolutions du site par la mise à
              disposition de nouvelles fonctionnalités ou la suppression ou la
              modification de fonctionnalités existantes.
            </p>
            <p>
              Il est donc conseillé à l'utilisateur de se référer avant toute
              navigation à la dernière version des CGU, accessible à tout moment
              sur le site. En cas de désaccord avec les CGU, aucun usage du site
              ne saurait être effectué par l'utilisateur.
            </p>

            <h5>ARTICLE 5. ACCÈS ET NAVIGATION</h5>

            <p>
              L'éditeur met en œuvre les solutions techniques à sa disposition
              pour permettre l'accès au site 24 heures sur 24, 7 jours sur 7. Il
              pourra néanmoins à tout moment suspendre, limiter ou interrompre
              l'accès au site ou à certaines pages de celui-ci afin de procéder
              à des mises à jours, des modifications de son contenu ou tout
              autre action jugée nécessaire au bon fonctionnement du site.
            </p>
            <p>
              La connexion et la navigation sur le site EEIA valent acceptation
              sans réserve des présentes Conditions Générales d'Utilisation,
              quelques soient les moyens techniques d'accès et les terminaux
              utilisés.
            </p>
            <p>
              Les présentes CGU s'appliquent, en tant que de besoin, à toute
              déclinaison ou extension du site sur les réseaux sociaux et/ou
              communautaires existants ou à venir.
            </p>

            <h5>ARTICLE 6. GESTION DU SITE</h5>
            <p>
              Pour la bonne gestion du site, l'éditeur pourra à tout
              moment&nbsp;:
            </p>
            <ul>
              <li>
                Suspendre, interrompre ou limiter l'accès à tout ou partie du
                site, réserver l'accès au site, ou à certaines parties du site,
                à une catégorie déterminée d'internaute&nbsp;;
              </li>
            </ul>
            <ul>
              <li>
                Supprimer toute information pouvant en perturber le
                fonctionnement ou entrant en contravention avec les lois
                nationales ou internationales, ou avec les règles de la
                Nétiquette&nbsp;;
              </li>
            </ul>
            <ul>
              <li>Suspendre le site afin de procéder à des mises à jour.</li>
            </ul>

            <h5>ARTICLE 7. SERVICES RÉSERVÉS AUX UTILISATEURS INSCRITS</h5>
            <p style="padding-left: 30px"><br />1. INSCRIPTION</p>
            <p>
              L'accès à certains services est conditionné par l'inscription de
              l'utilisateur.
            </p>
            <p>
              L'inscription et l'accès aux services du site sont réservés
              exclusivement aux personnes physiques capables, ayant rempli et
              validé le formulaire d'inscription disponible en ligne sur le site
              EEIA, ainsi que les présentes Conditions Générales d'Utilisation.
            </p>
            <p>
              Lors de son inscription, l'utilisateur s'engage à fournir des
              informations exactes, sincères et à jour sur sa personne et son
              état civil. L'utilisateur devra en outre procéder à une
              vérification régulière des données le concernant afin d'en
              conserver l'exactitude.
            </p>
            <p>
              L'utilisateur doit ainsi fournir impérativement une adresse e-mail
              valide. Une adresse de messagerie électronique ne peut être
              utilisée plusieurs fois pour s'inscrire aux services.
            </p>
            <p>
              Toute communication réalisée par EEIA et ses partenaires est en
              conséquence réputée avoir été réceptionnée et lue par
              l'utilisateur. Ce dernier s'engage donc à consulter régulièrement
              les messages reçus sur cette adresse e-mail et à répondre dans un
              délai raisonnable si cela est nécessaire.
            </p>
            <p>
              Une seule inscription aux services du site est admise par personne
              physique.
            </p>
            <p>
              Pour accéder aux services, l’Utilisateur devra s'identifier à
              l'aide de son nom d’utilisateur et de son mot de passe.
            </p>
            <p>
              Le mot de passe est modifiable en ligne par l'utilisateur dans son
              Espace personnel. Le mot de passe est personnel et confidentiel. A
              ce titre, il s’en interdit toute divulgation. Dans le cas
              contraire, il restera seul responsable de l’usage qui en sera
              fait..
            </p>
            <p>
              EEIA se réserve en tout état de cause la possibilité de refuser
              une demande d'inscription aux services en cas de non-respect par
              l'utilisateur des dispositions des présentes Conditions Générales
              d'Utilisation.<br /><br />
            </p>
            <p style="padding-left: 30px">2. DÉSINSCRIPTION</p>
            <p>
              L'utilisateur régulièrement inscrit pourra à tout moment demander
              sa désinscription en adressant un envoyant un email à
              <a href="mailto:eeia@beninexcellence.org"
                >eeia@beninexcellence.org</a
              >.<br /><br />
            </p>
            <p style="padding-left: 30px">
              3. SUPPRESSION DE L'ESPACE PERSONNEL À L'INITIATIVE DE L'ÉDITEUR
              DU SITE
            </p>
            <p>
              Il est porté à la connaissance de l'utilisateur que l'éditeur se
              réserve le droit de supprimer l'espace personnel de tout
              Utilisateur qui contreviendrait aux présentes conditions
              d'utilisation, et plus particulièrement dans les cas
              suivants&nbsp;:
            </p>
            <ul>
              <li>
                Si l'utilisateur fait une utilisation illicite du site&nbsp;;
              </li>
            </ul>
            <ul>
              <li>
                Si l'utilisateur, lors de la création de son espace personnel,
                transmet volontairement des informations erronées au site&nbsp;;
              </li>
            </ul>
            <p>
              Dans le cas où l'éditeur déciderait de supprimer l'espace
              personnel de l'utilisateur pour l'une de ces raisons, celle-ci ne
              saurait constituer un dommage pour l'utilisateur dont le compte a
              été supprimé.
            </p>
            <p>
              Cette suppression ne saurait constituer une renonciation aux
              poursuites judiciaires que l'éditeur pourrait entreprendre à
              l'égard de l'utilisateur étant contrevenu à ces règles.
            </p>

            <h5>ARTICLE 8. RESPONSABILITÉS</h5>
            <p>
              L'éditeur n'est responsable que du contenu qu'il a lui-même édité.
            </p>
            <p>L'éditeur n'est pas responsable&nbsp;:</p>
            <ul>
              <li>
                En cas de problématiques ou défaillances techniques,
                informatiques ou de compatibilité du site avec un matériel ou
                logiciel quel qu'il soit&nbsp;;
              </li>
            </ul>
            <ul>
              <li>
                Des dommages directs ou indirects, matériels ou immatériels,
                prévisibles ou imprévisibles résultant de l'utilisation ou des
                difficultés d'utilisation du site ou de ses services&nbsp;;
              </li>
            </ul>
            <ul>
              <li>
                Des caractéristiques intrinsèques de l'Internet, notamment
                celles relatives au manque de fiabilité et au défaut de
                sécurisation des informations y circulant&nbsp;;
              </li>
            </ul>
            <p>
              Par ailleurs, le site ne saurait garantir l'exactitude, la
              complétude, et l'actualité des informations qui y sont diffusées.
            </p>
            <p>L'utilisateur est responsable&nbsp;:</p>
            <ul>
              <li>De la protection de son matériel et de ses données&nbsp;;</li>
            </ul>
            <ul>
              <li>
                De l'utilisation qu'il fait du site ou de ses services&nbsp;;
              </li>
            </ul>
            <ul>
              <li>
                S'il ne respecte ni la lettre, ni l'esprit des présentes CGU.
              </li>
            </ul>

            <h5>ARTICLE 10. CONFIDENTIALITE</h5>
            <p style="padding-left: 30px">
              1. DONNEES COLLECTEES ET TRAITEES, ET MODE DE COLLECTION DES
              DONNEES
            </p>
            <p>
              Conformément aux dispositions de l'article 5 du Règlement européen
              2016/679, la collecte et le traitement des données des
              utilisateurs du site respectent les principes suivants&nbsp;:
            </p>
            <ul>
              <li>
                Licéité, loyauté et transparence&nbsp;: les données ne peuvent
                être collectées et traitées qu'avec le consentement de
                l'utilisateur propriétaire des données. A chaque fois que des
                données à caractère personnel seront collectées, il sera indiqué
                à l'utilisateur que ses données sont collectées, et pour quelles
                raisons ses données sont collectées&nbsp;;
              </li>
            </ul>
            <ul>
              <li>
                Finalités limitées&nbsp;: la collecte et le traitement des
                données sont exécutés pour répondre à un ou plusieurs objectifs
                déterminés dans les présentes conditions générales
                d'utilisation&nbsp;;
              </li>
            </ul>
            <ul>
              <li>
                Minimisation de la collecte et du traitement des données&nbsp;:
                seules les données nécessaires à la bonne exécution des
                objectifs poursuivis par le site sont collectées&nbsp;;
              </li>
              <li>
                Conservation des données réduites dans le temps&nbsp;: les
                données sont conservées pour une durée limitée, dont
                l'utilisateur est informé. Si la durée de conservation ne peut
                être communiquée à l'utilisateur&nbsp;;
              </li>
            </ul>
            <ul>
              <li>
                Intégrité et confidentialité des données collectées et
                traitées&nbsp;: le responsable du traitement des données
                s'engage à garantir l'intégrité et la confidentialité des
                données collectées.
              </li>
            </ul>
            <p>
              <br />Afin d'être licite, et ce conformément aux exigences de
              l'article 6 du règlement européen 2016/679, la collecte et le
              traitement des données à caractère personnel ne pourront
              intervenir que s'ils respectent au moins l'une des conditions
              ci-après énumérées&nbsp;:
            </p>
            <ul>
              <li>
                L'utilisateur a expressément consenti au traitement&nbsp;;
              </li>
            </ul>
            <ul>
              <li>
                Le traitement est nécessaire à la bonne exécution d'un contrat;
              </li>
            </ul>
            <ul>
              <li>Le traitement répond à une obligation légale;</li>
            </ul>
            <ul>
              <li>
                Le traitement s'explique par une nécessité liée à la sauvegarde
                des intérêts vitaux de la personne concernée ou d'une autre
                personne physique;
              </li>
            </ul>
            <ul>
              <li>
                Le traitement peut s'expliquer par une nécessité liée à
                l'exécution d'une mission d'intérêt public ou qui relève de
                l'exercice de l'autorité publique;
              </li>
            </ul>
            <ul>
              <li>
                Le traitement et la collecte des données à caractère personnel
                sont nécessaires aux fins des intérêts légitimes et privés
                poursuivis par le responsable du traitement ou par un tiers.
              </li>
            </ul>
            <p>
              <br />Les données à caractère personnel collectées sur le site
              EEIA sont les suivantes&nbsp;:
            </p>
            <p style="padding-left: 30px">
              <span id="span_id_liste_donnees_collectees"
                >- Prénoms, nom, numéro de téléphone, email ; <br />
                - Date de naissance, pièce d’identité, documents scolaires ;
                <br />
                <br />
                Ces données sont collectées lorsque l'utilisateur effectue l'une
                des opérations suivantes sur le site : <br />
                - Lorsque l'utilisateur créée un compte ; <br />
                - Lorsque l’utilisateur remplit le formulaire de candidature et
                soumet.</span
              >
            </p>
            <p>
              La collecte et le traitement des données répondent aux finalités
              suivantes&nbsp;:
            </p>
            <p style="padding-left: 30px">
              <span id="span_id_finalite_traitement_donnees"
                >L'ensemble des données fournies par l'utilisateur sera examiné
                par un jury de l’association Bénin Excellence dans l'objectif
                d'octroyer des bourses aux meilleurs candidats pour qu’ils
                puissent suivre la formation sur l’intelligence artificielle
                gratuitement.</span
              >
            </p>
            <p>
              Les données peuvent être transmises au(x) tiers ci-après
              énuméré(s)&nbsp;:
            </p>
            <p style="padding-left: 30px">
              <span id="span_id_transmission_donnees_tiers"
                >Les membres du jury mise en place par l’association Bénin
                Excellence.</span
              >
              <br /><br />
            </p>
            <p style="padding-left: 30px">2. HEBERGEMENT DES DONNEES</p>
            <p>
              Tel que mentionné plus haut, le site EEIA est hébergé par&nbsp;:
              <span id="span_id_denomination_sociale_de_l_hebergeur">LWS</span>,
              dont le siège est situé à l'adresse ci-après&nbsp;:
            </p>
            <p style="padding-left: 30px">
              <span id="span_id_siege_social_de_l_hebergeur"
                >10, RUE PENTHIEVRE 75008 PARIS FRANCE</span
              >
            </p>
            <p>
              Les données
            collectées et traitées par le site sont exclusivement hébergées en
            France. Le traitement se fait au Bénin.  <br /><br />
            </p>
            <p style="padding-left: 30px">
              3. LE RESPONSABLE DU TRAITEMENT DES DONNÉES
            </p>
            <p>
              Le responsable du traitement des données à caractère personnel
              est&nbsp;:
              <span id="span_id_prenom_nom_responsable"
                >le service informatique de la Fondation Vallet</span
              >. Il peut être contacté de la manière suivante&nbsp;:
            </p>
            <p style="padding-left: 30px">
              <span id="span_id_methode_contact_rtd"
                ><a href="mailto:eeia@beninexcellence.org"
                  >eeia@beninexcellence.org</a
                ></span
              ><br /><br />
            </p>
            <p style="padding-left: 30px">
              4. DONNEES PERSONNELLES DES PERSONNES MINEURES
            </p>
            <p>
              Si l'utilisateur est un mineur de moins de 18
            ans, l'accord d'un représentant légal sera requis afin que des
            données à caractère personnel puissent être collectées et traitées. <br /><br />
            </p>
            <p style="padding-left: 30px">
              5. DROITS DE L'UTILISATEUR ET PROCÉDURES DE MISE EN OEUVRE DES
              DROITS DE L'UTILISATEUR
            </p>
            <p>
              Conformément à la réglementation concernant le traitement des
              données à caractère personnel, l'utilisateur possède les droits
              ci-après énumérés.
            </p>
            <p>
              Afin que le responsable du traitement des données fasse droit à sa
              demande, l'utilisateur est tenu de lui communiquer&nbsp;: ses
              prénom et nom ainsi que son adresse e-mail, et si cela est
              pertinent, son numéro de compte ou d'espace personnel ou d'abonné.
            </p>
            <p>
              Le responsable du traitement des données est tenu de répondre à
              l'utilisateur dans un délai de 30 (trente) jours maximum.
            </p>
            <p>
              <em>a. Droit d'accès, de rectification et droit à l'oubli</em>
            </p>
            <p>
              L'utilisateur peut prendre connaissance, mettre à jour, modifier
              ou demander la suppression des données le concernant, en
              respectant la procédure ci-après énoncée&nbsp;:
            </p>
            <p style="padding-left: 30px">
              <span id="span_id_procedure_acces_donnees"
                >L'utilisateur doit envoyer un e-mail au responsable du
                traitement des données personnelles, en précisant l'objet de sa
                demande et en utilisant l'adresse e-mail de contact qui est
                fournie plus haut.</span
              >
            </p>
            <p>
              S'il en possède un, l'utilisateur a le droit de demander la
              suppression de son espace personnel en suivant la procédure
              suivante&nbsp;:
            </p>
            <p style="padding-left: 30px">
              <span id="span_id_procedure_suppression_compte"
                >L'utilisateur doit envoyer un e-mail au responsable du
                traitement des données, en précisant son adresse E-mail et son
                numéro de téléphone. La demande de suppression des données sera
                traitée dans un délai de 15 jours ouvrés.</span
              >
            </p>
            <p>
              <em
                >c. Droit à la limitation et à l'opposition du traitement des
                données</em
              >
            </p>
            <p>
              Enfin, l'utilisateur a le droit de demander la limitation ou de
              s'opposer au traitement de ses données par le site, sans que le
              site ne puisse refuser, sauf à démontrer l'existence de motifs
              légitimes et impérieux, pouvant prévaloir sur les intérêts et les
              droits et libertés de l'utilisateur.
            </p>
            <p>
              <em>d. Droit de déterminer le sort des données après la mort</em>
            </p>
            <p>
              Il est rappelé à l'utilisateur qu'il peut organiser quel doit être
              le devenir de ses données collectées et traitées s'il décède,
              conformément à la loi n°2016-1321 du 7 octobre 2016.
            </p>
            <p>
              <em>e. Droit de saisir l'autorité de contrôle compétente </em>
            </p>
            <p>
              Dans le cas où le responsable du traitement des données décide de
              ne pas répondre à la demande de l'utilisateur, et que
              l'utilisateur souhaite contester cette décision, il est en droit
              de saisir la CNIL (Commission Nationale de l'Informatique et des
              Libertés, https://www.cnil.fr) ou tout juge compétent.
            </p>
            <p style="padding-left: 30px">
              <br />6. OBLIGATIONS DU RESPONSABLE DU TRAITEMENT DES DONNÉES
            </p>
            <p>
              Le responsable du traitement s'engage à protéger les données à
              caractère personnel collectées, à ne pas les transmettre à des
              tiers sans que l'utilisateur n'en ait été informé et à respecter
              les finalités pour lesquelles ces données ont été collectées.
            </p>
            <p>
              Le site dispose d'un certificat SSL afin de garantir que les
              informations et le transfert des données transitant par le site
              sont sécurisés.
            </p>
            <p>
              De plus, le responsable du traitement des données s'engage à
              notifier l'utilisateur en cas de rectification ou de suppression
              des données, à moins que cela n'entraîne pour lui des formalités,
              coûts et démarches disproportionnés.
            </p>
            <p>
              Dans le cas où l'intégrité, la confidentialité ou la sécurité des
              données à caractère personnel de l'utilisateur est compromise, le
              responsable du traitement s'engage à informer l'utilisateur par
              tout moyen.
            </p>

            <h5>ARTICLE 11. PROPRIÉTÉ INTELLECTUELLE</h5>
            <p>
              La structuration du site mais aussi les textes, graphiques,
              images, photographies, sons, vidéos et applications informatiques
              qui le composent sont la propriété de l'éditeur et sont protégés
              comme tels par les lois en vigueur au titre de la propriété
              intellectuelle.
            </p>
            <p>
              Toute représentation, reproduction, adaptation ou exploitation
              partielle ou totale des contenus, marques déposées et services
              proposés par le site, par quelque procédé que ce soit, sans
              l'autorisation préalable, expresse et écrite de l'éditeur, est
              strictement interdite et serait susceptible de constituer une
              contrefaçon au sens des articles L. 335-2 et suivants du Code de
              la propriété intellectuelle. Et ce, à l'exception des éléments
              expressément désignés comme libres de droits sur le site.
            </p>
            <p>
              L'accès au site ne vaut pas reconnaissance d'un droit et, de
              manière générale, ne confère aucun droit de propriété
              intellectuelle relatif à un élément du site, lesquels restent la
              propriété exclusive de l'éditeur.
            </p>
            <p>
              Il est interdit à l'utilisateur d'introduire des données sur le
              site qui modifieraient ou qui seraient susceptibles d'en modifier
              le contenu ou l'apparence.
            </p>
            <h5>ARTICLE 12. Droit applicable et juridiction compétente</h5>
            <p>
              La législation française s'applique au présent contrat. En cas
              d'absence de résolution amiable d'un litige né entre les parties,
              les tribunaux français seront seuls compétents pour en connaître.
            </p>
          </div>
        </div>
      </div>

      <div class="text-center fs-6 mt-4">
        <img src="../assets/img/logo_be_vallet_pnud.png" height="100" alt="" />
        <p class="mb-1 text-muted">
          &copy; Bénin Excellence 2021–<span id="currentYear">2022</span>
        </p>
        <div class="mb-3 text-muted">
          <ul class="list-inline">
            <li class="list-inline-item">
              <router-link to="/register"
                >Aller sur la page d'inscription</router-link
              >
            </li>
            <li class="list-inline-item">
              <router-link to="/login"
                >Aller sur la page de connexion</router-link
              >
            </li>
          </ul>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  name: "CGU",
  inject: ["currentYearUrl"],
  components: {},
  mounted() {
    document.querySelector("body").style.backgroundColor = "#446ead";
    fetch(this.currentYearUrl)
      .then((result) => {
        result.text().then(function (text) {
          document.getElementById("currentYear").textContent = text;
        });
      })
      .catch(() => {
        document.getElementById("currentYear").textContent = "2022";
      });
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
  /* background-image: var(--bs-gradient)!important; */
  background-color: #446ead !important;
  font-size: 20px;
}

@media (min-width: 768px) {
  .form-control {
    line-height: 2.5;
  }
}

.btn-group-lg > .btn,
.btn-lg {
  padding: 0.5rem 1.5rem !important;
  font-weight: bold !important;
}

.form-wrapper {
  width: 100%;
  padding: 15px;
  max-width: 800px;
  margin: auto;
  margin-top: 11vh;
  margin-bottom: 8vh;
}

@media (max-width: 575.98px) {
  .form-wrapper {
    margin-top: 7.5vh !important;
    margin-bottom: 4vh !important;
  }

  .form-signin {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.form-signin {
  padding: 15px;
  width: 100%;
  background: white;
  border-radius: 8px;
}

.col p {
  text-align: justify;
}
</style>
