<template>
  <div class="container px-sm-0 bg-light">
    <div class="row adjust-margin">
      <div class="col">
        <div class="container px-sm-5" v-if="this.userProfile.step >= 0 && this.userProfile.step < 6">
          <div class="alert alert-danger fs-3 mt-4 text-center" role="alert" v-if="deadlinePassed">
            Les candidatures sont closes.
          </div>
          <keep-alive>
            <generality
              v-if="currentStep == 1"
              v-on:to-next="currentStep++"
              :previousData="step1"
              :step="userProfile.step"
              :lastName="userProfile.lastName"
              :firstName="userProfile.firstName"
              v-on:native-country-change="updateNativeCountry"
            ></generality>
          </keep-alive>
          <keep-alive>
            <career
              v-if="currentStep == 2"
              v-on:to-next="currentStep++"
              v-on:to-previous="currentStep--"
              :previousData="step2"
              :nativeCountry="nativeCountry"
              :step="userProfile.step"
              v-on:career-sent="updateStepTwoCategory"
            ></career>
          </keep-alive>
          <keep-alive>
            <motivation
              v-if="currentStep == 3"
              v-on:to-next="currentStep++"
              v-on:to-previous="currentStep--"
              :previousData="step3"
              :nativeCountry="nativeCountry"
              :step="userProfile.step"
            ></motivation>
          </keep-alive>
          <keep-alive>
            <social
              v-if="currentStep == 4"
              v-on:to-next="currentStep++"
              v-on:to-previous="currentStep--"
              :previousData="step4"
              :nativeCountry="nativeCountry"
              :step="userProfile.step"
            ></social>
          </keep-alive>
          <keep-alive>
            <evaluation
              v-if="currentStep == 5"
              v-on:to-next="onAutoEvaluationSubmited"
              v-on:to-previous="currentStep--"
              :previousData="step5"
              :step="userProfile.step"
              :evaluationHasBeenSent="evaluationHasBeenSent"
            ></evaluation>
          </keep-alive>
          <keep-alive>
            <attachments
              v-if="currentStep == 6"
              v-on:to-previous="currentStep--"
              v-on:evaluation-sent="updateUserStep"
              :previousAttachments="step6"
              :previousData="step2"
              :step="userProfile.step"
              :stepTwoCategory="stepTwoCategory"
            ></attachments>
          </keep-alive>
        </div>
        <div v-else-if="this.userProfile.step >= 6 || this.userProfile.step == -1" class="container px-sm-5 my-4">
          <div class="row">
            <div class="col">
              <div class="alert alert-info text-center" role="alert">
                <span class="fs-3"
                  >Vous avez déjà soumis votre dossier de candidature.</span
                >
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-auto">
              <router-link :to="{ name: 'Status' }" class="btn btn-lg btn-primary">
                Suivre mon dossier
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Generality from "../components/EeiaGenerality.vue";
import Career from "../components/EeiaCareer.vue";
import Motivation from "../components/EeiaMotivation.vue";
import Evaluation from "../components/Evaluation.vue";
import Social from "../components/EeiaSocial.vue";
import Attachments from "../components/EeiaAttachments.vue";

export default {
  name: "NewCandidacy",
  inject: ["ToastSuccess", "ToastError", "currentYearUrl"],
  components: {
    Generality,
    Career,
    Motivation,
    Evaluation,
    Social,
    Attachments,
  },
  mounted() {
    // document.querySelector("body").style.backgroundColor = "#446eada1";
    this.checkDeadline();
  },
  created() {
    this.getAllSteps();
  },
  data() {
    return {
      currentStep: 0,
      firstStep: 1,
      finalStep: 7,
      step1: {},
      step2: {},
      step3: {},
      step4: {},
      step5: {},
      step6: {},
      userProfile: {},
      nativeCountry: "",
      stepTwoCategory: "",
      evaluationHasBeenSent: false,
      deadlinePassed: false,
    };
  },
  methods: {
    getAllSteps() {
      this.$http.get("new-candidacies").then(async (result) => {
        // if (!result.data.userProfile.email_verified) {
        //   this.$router.push({ name: "EmailVerification" });
        // }
        await this.setAllLocalSteps(result);

        if (localStorage.getItem("currentStep")) {
          this.currentStep = parseInt(localStorage.getItem("currentStep"));
        } else {
          this.currentStep = 1;
        }
      });
    },
    async setAllLocalSteps(result) {
      this.step1 = result.data.step1;
      this.step2 = result.data.step2;
      this.step3 = result.data.step3;
      this.step4 = result.data.step4;
      this.step5 = result.data.step5;
      this.step6 = result.data.step6;
      this.userProfile = result.data.userProfile;
      this.displayUserInformations(result.data.userProfile);
      this.nativeCountry = result.data.step1.country;
      this.stepTwoCategory = result.data.step2.category
    },
    displayUserInformations(userProfile) {
      document.getElementById("navbarLastName").textContent =
        userProfile.lastName;
      document.getElementById("navbarFirstName").textContent =
        userProfile.firstName;
    },
    updateUserStep() {
      this.userProfile.step = 6;
      // document.getElementById("newCandidacyLink").textContent =
      //   "Modifier ma candidature";
      // this.$emit("update-nav");
      this.$router.push({ name: "Home" });
      localStorage.setItem("candidature_step", 6);
    },
    updateStepTwoCategory(e) {
      this.stepTwoCategory = e
    },
    updateNativeCountry(e) {
      this.nativeCountry = e;
    },
    onAutoEvaluationSubmited() {
      this.currentStep++;
      this.evaluationHasBeenSent = true;
    },
    checkDeadline() {
      fetch(this.currentYearUrl.replace('current-year', 'deadline'))
      .then(response => {
        if (!response.ok) {
          return { deadlinePassed: false };
        }
        return response.json();
      })
      .then(data => {
        this.deadlinePassed = data?.deadlinePassed;
      })
    },
  },
};
</script>

<style scoped>
@media all and (max-width: 991px) {
  /* .adjust-margin {
    margin: 0px !important;
  } */
}
</style>