
export const cities = [

    { value: "Abomey", text: "Abomey" },
    { value: "Abomey-Calavi", text: "Abomey-Calavi" },
    { value: "Adja-ouèrè", text: "Adja-ouèrè" },
    { value: "Adjarra", text: "Adjarra" },
    { value: "Adjohoun", text: "Adjohoun" },
    { value: "Agbangnizoun", text: "Agbangnizoun" },
    { value: "Aguégués", text: "Aguégués" },
    { value: "Akpro-Missérété", text: "Akpro-Missérété" },
    { value: "Allada", text: "Allada" },
    { value: "Aplahoué", text: "Aplahoué" },
    { value: "Athiémé", text: "Athiémé" },
    { value: "Avrankou", text: "Avrankou" },
    { value: "Banikoara", text: "Banikoara" },
    { value: "Bantè", text: "Bantè" },
    { value: "Bassila", text: "Bassila" },
    { value: "Bembèrèkè", text: "Bembèrèkè" },
    { value: "Bohicon", text: "Bohicon" },
    { value: "Bonou", text: "Bonou" },
    { value: "Bopa", text: "Bopa" },
    { value: "Boukoumbé", text: "Boukoumbé" },
    { value: "Cobli", text: "Cobli" },
    { value: "Comè", text: "Comè" },
    { value: "Copargo", text: "Copargo" },
    { value: "Cotonou", text: "Cotonou" },
    { value: "Covè", text: "Covè" },
    { value: "Dangbo", text: "Dangbo" },
    { value: "Dassa-Zoumè", text: "Dassa-Zoumè" },
    { value: "Djakotomey", text: "Djakotomey" },
    { value: "Djidja", text: "Djidja" },
    { value: "Djougou", text: "Djougou" },
    { value: "Dogbo", text: "Dogbo" },
    { value: "Glazoué", text: "Glazoué" },
    { value: "Gogonou", text: "Gogonou" },
    { value: "Grand-Popo", text: "Grand-Popo" },
    { value: "Houéyogbé", text: "Houéyogbé" },
    { value: "Ifangni", text: "Ifangni" },
    { value: "Kalalé", text: "Kalalé" },
    { value: "Kandi", text: "Kandi" },
    { value: "Karimama", text: "Karimama" },
    { value: "Kérou", text: "Kérou" },
    { value: "Kétou", text: "Kétou" },
    { value: "Klouékanmey", text: "Klouékanmey" },
    { value: "Kouandé", text: "Kouandé" },
    { value: "Kpomassè", text: "Kpomassè" },
    { value: "Lalo", text: "Lalo" },
    { value: "Lokossa", text: "Lokossa" },
    { value: "Malanville", text: "Malanville" },
    { value: "Matéri", text: "Matéri" },
    { value: "N’Dali", text: "N’Dali" },
    { value: "Natitingou Péhunco", text: "Natitingou Péhunco" },
    { value: "Nikki", text: "Nikki" },
    { value: "Ouaké", text: "Ouaké" },
    { value: "Ouessè", text: "Ouessè" },
    { value: "Ouidah", text: "Ouidah" },
    { value: "Ouinhi", text: "Ouinhi" },
    { value: "Parakou", text: "Parakou" },
    { value: "Pèrèrè", text: "Pèrèrè" },
    { value: "Pobè", text: "Pobè" },
    { value: "Porto-Novo", text: "Porto-Novo" },
    { value: "Sakété", text: "Sakété" },
    { value: "Savalou", text: "Savalou" },
    { value: "Savè", text: "Savè" },
    { value: "Ségbana", text: "Ségbana" },
    { value: "Sèmè-Podji", text: "Sèmè-Podji" },
    { value: "Sinendé", text: "Sinendé" },
    { value: "Sô-Ava", text: "Sô-Ava" },
    { value: "Tanguiéta", text: "Tanguiéta" },
    { value: "Tchaourou", text: "Tchaourou" },
    { value: "Toffo", text: "Toffo" },
    { value: "Tori", text: "Tori" },
    { value: "Toukountouna", text: "Toukountouna" },
    { value: "Toviklin", text: "Toviklin" },
    { value: "Za-kpota", text: "Za-kpota" },
    { value: "Zagnanado", text: "Zagnanado" },
    { value: "Zê", text: "Zê" },
    { value: "Zogbodomè", text: "Zogbodomè" },
];








