<template>
  <div class="form-check">
    <input
      :name="name"
      type="radio"
      :value="value"
      @input="handleChange"
      @blur="handleBlur"
      class="form-check-input"
      :class="{ 'is-invalid': !!errorMessage }"
      :id="id"
      :checked="isChecked"
    />
    <label class="form-check-label" :for="id">
      {{ label }} <span v-if="tooltip != 'false'" data-bs-toggle="tooltip" data-bs-placement="top" :title="tooltip">💡</span>
    </label>
    <div class="invalid-feedback">
      Vous devez choisir une valeur.
    </div>
  </div>
</template>

<script>
import { useField } from "vee-validate";

export default {
  name: "SocialRadio",
  props: {
    value: {
      type: String,
      default: "false",
    },
    name: {
      type: String,
    },
    label: {
      type: String,
    },
    tooltip: {
      type: String,
      default: "false",
    },
    isChecked: {
      type: Boolean,
      default: false,
    },
  },
  // eslint-disable-next-line vue/no-setup-props-destructure
  setup({ name, value }) {
    const { checked, handleChange, errorMessage, handleBlur } = useField(
      name,
      undefined,
      {
        type: "radio",
        checkedValue: value,
      }
    );

    let id = name + '-' + (Math.floor(Math.random() * (1000 - 10) ) + 10)

    return {
      checked,
      handleChange,
      handleBlur,
      errorMessage,
      id
    };
  },
};
</script>

<style  scoped>
.form-check-input {
    width: 1.5em;
    height: 1.5em;
    margin-top: 0em;
}

.form-check .form-check-input {
    float: left;
    margin-left: -1.5em;
    margin-right: 10px;
    cursor: pointer;
}

label {
    display: block !important;
    cursor: pointer;
}
</style>