<template>
  <div class="row">
    <VeeForm
      class="col"
      v-slot="{ handleSubmit, meta }"
      :validation-schema="schema"
      as="div"
    >
      <div class="alert alert-danger d-none" id="alertError" role="alert"></div>
      <form
        @submit="handleSubmit($event, onSubmit)"
        class="row"
        id="generalityForm"
        ref="generalityForm"
      >
        <div class="col">
          <!-- //* Parcours -->
          <!-- <div class="alert alert-danger fs-5 mt-5 text-center" role="alert">
            Les candidatures sont closes.
          </div> -->
          <div class="row mb-2 mt-5">
            <div class="col mt-1">
              <h2 class="section-title text-uppercase">Parcours</h2>
            </div>
          </div>
          <div class="row">
            <!-- //? Catégorie socioprofessionnelle -->
            <div class="mb-4 col-lg-4 col-md-6 col-sm-12 col-12">
              <label for="category" class="form-label required"
                >Catégorie socioprofessionnelle</label
              >
              <Field
                name="category"
                v-model="category"
                label="catégorie socioprofessionnelle"
                v-slot="{ field, errorMessage }"
                @input="categoryChanged"
              >
                <select
                  class="form-select"
                  id="category"
                  v-bind="field"
                  :class="{ 'is-invalid': errorMessage }"
                  aria-label="eeia candidature select"
                >
                  <option value="">Faites un choix</option>
                  <option value="Elève">Elève</option>
                  <option value="Etudiant(e)">Etudiant(e)</option>
                  <option value="Enseignant(e)">Enseignant(e)</option>
                  <option value="Professionnel(le)">Professionnel(le)</option>
                  <option value="other">Autre</option>
                </select>
              </Field>
              <ErrorMessage name="category" as="div" class="invalid-feedback" />
            </div>
            <!-- //? Autre catégorie -->
            <div
              class="mb-4 col-lg-4 col-md-6 col-sm-12 col-12"
              v-if="category == 'other'"
            >
              <label for="otherCategory" class="form-label required"
                >Autre catégorie</label
              >
              <Field
                name="otherCategory"
                v-model="otherCategory"
                type="text"
                label="autre catégorie"
                v-slot="{ field, errorMessage }"
              >
                <input
                  id="otherCategory"
                  v-bind="field"
                  placeholder="Saisissez un texte"
                  class="form-control"
                  :class="{ 'is-invalid': errorMessage }"
                />
              </Field>
              <ErrorMessage
                name="otherCategory"
                as="div"
                class="invalid-feedback"
              />
            </div>
            <!-- //? Niveau d’étude -->
            <div class="mb-4 col-lg-4 col-md-6 col-sm-12 col-12">
              <label for="level" class="form-label required"
                >Niveau d’étude</label
              >
              <Field
                name="level"
                label="niveau d’étude"
                v-model="level"
                v-slot="{ field, errorMessage }"
              >
                <select
                  v-if="category == 'Elève'"
                  class="form-select"
                  id="level"
                  v-bind="field"
                  :class="{ 'is-invalid': errorMessage }"
                  aria-label="eeia candidature select"
                >
                  <option value="">Faites un choix</option>
                  <option value="Seconde">Seconde</option>
                  <option value="Première">Première</option>
                  <option value="Terminale">Terminale</option>
                </select>
                <select
                  v-else-if="category == 'other'"
                  class="form-select"
                  id="level"
                  v-bind="field"
                  :class="{ 'is-invalid': errorMessage }"
                  aria-label="eeia candidature select"
                >
                  <option value="">Faites un choix</option>
                  <option value="Seconde">Seconde</option>
                  <option value="Première">Première</option>
                  <option value="Terminale">Terminale</option>
                  <option value="Cycle 1 (Bac + 1 à Bac + 3)">
                    Cycle 1 (Bac + 1 à Bac + 3)
                  </option>
                  <option value="Cycle 2 (Bac + 4 à Bac + 5)">
                    Cycle 2 (Bac + 4 à Bac + 5)
                  </option>
                  <option value="Cycle 3">Cycle 3</option>
                </select>
                <select
                  class="form-select"
                  id="level"
                  v-bind="field"
                  :class="{ 'is-invalid': errorMessage }"
                  aria-label="eeia candidature select"
                  v-else
                >
                  <option value="">Faites un choix</option>
                  <option value="Cycle 1 (Bac + 1 à Bac + 3)">
                    Cycle 1 (Bac + 1 à Bac + 3)
                  </option>
                  <option value="Cycle 2 (Bac + 4 à Bac + 5)">
                    Cycle 2 (Bac + 4 à Bac + 5)
                  </option>
                  <option value="Cycle 3">Cycle 3</option>
                </select>
              </Field>
              <ErrorMessage name="level" as="div" class="invalid-feedback" />
            </div>
            <!-- //? Série/Métier -->
            <div
              class="mb-4 col-lg-4 col-md-6 col-sm-12 col-12"
              v-if="category == 'Elève' || category == 'other'"
            >
              <label for="job" class="form-label required">Série/Métier</label>
              <Field
                name="job"
                type="text"
                label="Série/Filière/Métier"
                v-slot="{ field, errorMessage }"
                v-model="job"
              >
                <input
                  id="job"
                  v-bind="field"
                  placeholder="Saisissez un texte"
                  class="form-control"
                  :class="{ 'is-invalid': errorMessage }"
                />
              </Field>
              <ErrorMessage name="job" as="div" class="invalid-feedback" />
            </div>
            <!-- //? Matière enseignée -->
            <div
              class="mb-4 col-lg-4 col-md-6 col-sm-12 col-12"
              v-if="category == 'Enseignant(e)'"
            >
              <label for="teacherTaughtSubject" class="form-label required"
                >Matière enseignée</label
              >
              <Field
                name="teacherTaughtSubject"
                type="text"
                label="matière enseignée"
                v-slot="{ field, errorMessage }"
                v-model="teacherTaughtSubject"
              >
                <input
                  id="teacherTaughtSubject"
                  v-bind="field"
                  placeholder="Saisissez un texte"
                  class="form-control"
                  :class="{ 'is-invalid': errorMessage }"
                />
              </Field>
              <ErrorMessage
                name="teacherTaughtSubject"
                as="div"
                class="invalid-feedback"
              />
            </div>
            <!-- //? Formation de base -->
            <div
              class="mb-4 col-lg-4 col-md-6 col-sm-12 col-12"
              v-if="category == 'Enseignant(e)'"
            >
              <label for="teacherStudyField" class="form-label required"
                >Formation de base</label
              >
              <Field
                name="teacherStudyField"
                label="formation de base"
                v-model="teacherStudyField"
                v-slot="{ field, errorMessage }"
              >
                <select
                  class="form-select"
                  id="teacherStudyField"
                  v-bind="field"
                  :class="{ 'is-invalid': errorMessage }"
                  aria-label="eeia candidature select"
                >
                  <option value="">Cliquer pour faire un choix</option>
                  <option
                    v-for="item in fields"
                    :key="item.value"
                    :value="item.value"
                  >
                    {{ item.text }}
                  </option>
                </select>
              </Field>
              <ErrorMessage
                name="teacherStudyField"
                as="div"
                class="invalid-feedback"
              />
            </div>
            <!-- //? Autre formation de base -->
            <div
              class="mb-4 col-lg-4 col-md-6 col-sm-12 col-12"
              v-if="teacherStudyField == 'other' && category == 'Enseignant(e)'"
            >
              <label for="otherTeacherStudyField" class="form-label required"
                >Autre formation de base</label
              >
              <Field
                name="otherTeacherStudyField"
                v-model="otherTeacherStudyField"
                type="text"
                label="formation de base"
                v-slot="{ field, errorMessage }"
              >
                <input
                  id="otherTeacherStudyField"
                  v-bind="field"
                  placeholder="Saisissez un texte"
                  class="form-control"
                  :class="{ 'is-invalid': errorMessage }"
                />
              </Field>
              <ErrorMessage
                name="otherTeacherStudyField"
                as="div"
                class="invalid-feedback"
              />
            </div>
            <!-- //? Domaine d’étude -->
            <div
              class="mb-4 col-lg-4 col-md-6 col-sm-12 col-12"
              v-if="category == 'Etudiant(e)'"
            >
              <label for="studyField" class="form-label required"
                >Domaine d’étude</label
              >
              <Field
                name="studyField"
                label="domaine d’étude"
                v-model="studyField"
                v-slot="{ field, errorMessage }"
              >
                <select
                  class="form-select"
                  id="studyField"
                  v-bind="field"
                  :class="{ 'is-invalid': errorMessage }"
                  aria-label="eeia candidature select"
                >
                  <option value="">Cliquer pour faire un choix</option>
                  <option
                    v-for="item in fields"
                    :key="item.value"
                    :value="item.value"
                  >
                    {{ item.text }}
                  </option>
                </select>
              </Field>
              <ErrorMessage
                name="studyField"
                as="div"
                class="invalid-feedback"
              />
            </div>
            <!-- //? Autre domaine d'étude -->
            <div
              class="mb-4 col-lg-4 col-md-6 col-sm-12 col-12"
              v-if="studyField == 'other' && category == 'Etudiant(e)'"
            >
              <label for="otherStudyField" class="form-label required"
                >Autre domaine d'étude</label
              >
              <Field
                name="otherStudyField"
                v-model="otherStudyField"
                type="text"
                label="domaine d'étude"
                v-slot="{ field, errorMessage }"
              >
                <input
                  id="otherStudyField"
                  v-bind="field"
                  placeholder="Saisissez un texte"
                  class="form-control"
                  :class="{ 'is-invalid': errorMessage }"
                />
              </Field>
              <ErrorMessage
                name="otherStudyField"
                as="div"
                class="invalid-feedback"
              />
            </div>
            <!-- //? Secteur de travail -->
            <div
              class="mb-4 col-lg-4 col-md-6 col-sm-12 col-12"
              v-if="category == 'Professionnel(le)'"
            >
              <label for="workingSector" class="form-label required"
                >Secteur de travail</label
              >
              <Field
                name="workingSector"
                label="secteur de travail"
                v-model="workingSector"
                v-slot="{ field, errorMessage }"
              >
                <select
                  class="form-select"
                  id="workingSector"
                  v-bind="field"
                  :class="{ 'is-invalid': errorMessage }"
                  aria-label="eeia candidature select"
                >
                  <option value="">Cliquer pour faire un choix</option>
                  <option
                    v-for="item in fields"
                    :key="item.value"
                    :value="item.value"
                  >
                    {{ item.text }}
                  </option>
                </select>
              </Field>
              <ErrorMessage
                name="workingSector"
                as="div"
                class="invalid-feedback"
              />
            </div>
            <!-- //? Autre secteur de travail -->
            <div
              class="mb-4 col-lg-4 col-md-6 col-sm-12 col-12"
              v-if="workingSector == 'other' && category == 'Professionnel(le)'"
            >
              <label for="otherWorkingSector" class="form-label required"
                >Autre secteur de travail</label
              >
              <Field
                name="otherWorkingSector"
                v-model="otherWorkingSector"
                type="text"
                label="secteur de travail"
                v-slot="{ field, errorMessage }"
              >
                <input
                  id="otherWorkingSector"
                  v-bind="field"
                  placeholder="Saisissez un texte"
                  class="form-control"
                  :class="{ 'is-invalid': errorMessage }"
                />
              </Field>
              <ErrorMessage
                name="otherWorkingSector"
                as="div"
                class="invalid-feedback"
              />
            </div>
            <!-- //? Pays Origine -->
            <div class="mb-4 col-lg-4 col-md-6 col-sm-12 col-12 d-none">
              <label for="countryNative" class="form-label required"
                >Pays d'origine</label
              >
              <Field
                name="countryNative"
                v-model="countryNative"
                label="Pays (Résidence/origine)"
                v-slot="{ field, errorMessage }"
              >
                <input
                  id="countryNative"
                  v-bind="field"
                  placeholder="Ne rien écrire ici"
                  class="form-control"
                  :class="{ 'is-invalid': errorMessage }"
                  disabled
                />
              </Field>
              <ErrorMessage
                name="countryNative"
                as="div"
                class="invalid-feedback"
              />
            </div>
            <!-- //? Pays (Résidence/étude) -->
            <div class="mb-4 col-lg-4 col-md-6 col-sm-12 col-12" v-if="nativeCountry != 'Bénin'">
              <label for="country" class="form-label required"
                >Pays d'étude</label
              >
              <Field
                name="country"
                v-model="country"
                label="Pays d'étude"
                v-slot="{ field, errorMessage }"
              >
                <select
                  class="form-select"
                  id="country"
                  v-bind="field"
                  :class="{ 'is-invalid': errorMessage }"
                  aria-label="eeia candidature select"
                >
                  <option value="">Cliquer pour faire un choix</option>
                  <option
                    v-for="item in countries"
                    :key="item.value"
                    :value="item.value"
                  >
                    {{ item.text }}
                  </option>
                </select>
              </Field>
              <ErrorMessage name="country" as="div" class="invalid-feedback" />
            </div>
            <!-- //? Établissement/Structure de provenance -->
            <div class="mb-4 col-lg-4 col-md-6 col-sm-12 col-12">
              <label for="establishment" class="form-label required"
                >Établissement/Structure de provenance</label
              >
              <Field
                name="establishment"
                type="text"
                label="Établissement/Structure de provenance"
                v-slot="{ field, errorMessage }"
                v-model="establishment"
              >
                <input
                  id="establishment"
                  v-bind="field"
                  placeholder="Saisissez un texte"
                  class="form-control"
                  :class="{ 'is-invalid': errorMessage }"
                  aria-describedby="establishmentHelp"
                />
                <div id="establishmentHelp" class="form-text">
                  <span class="text-decoration-underline">Ex:</span> École,
                  université, entreprise...
                </div>
              </Field>
              <ErrorMessage
                name="establishment"
                as="div"
                class="invalid-feedback"
              />
            </div>
            <!-- //? Département -->
            <div
              class="mb-4 col-lg-4 col-md-6 col-sm-12 col-12"
              v-if="nativeCountry == 'Bénin'"
            >
              <label for="beninDepartment" class="form-label required">
                Département
                <span class="fw-lighter"
                  ><i>(de l'école ou du lieu de travail)</i></span
                >
              </label>
              <Field
                name="beninDepartment"
                label="département"
                v-model="beninDepartment"
                v-slot="{ field, errorMessage }"
              >
                <select
                  class="form-select"
                  id="beninDepartment"
                  v-model="beninDepartment"
                  v-bind="field"
                  :class="{ 'is-invalid': errorMessage }"
                  aria-label="eeia candidature select"
                >
                  <option value="">Cliquer pour faire un choix</option>
                  <option
                    v-for="item in departments"
                    :key="item.value"
                    :value="item.value"
                  >
                    {{ item.text }}
                  </option>
                </select>
              </Field>
              <ErrorMessage
                name="beninDepartment"
                as="div"
                class="invalid-feedback"
              />
            </div>
            <!-- //? Département -->
            <div class="mb-4 col-lg-4 col-md-6 col-sm-12 col-12" v-else>
              <label for="department" class="form-label">
                Département
                <span class="fw-lighter"
                  ><i>(de l'école ou du lieu de travail)</i></span
                >
              </label>
              <Field
                name="department"
                type="text"
                label="départment"
                v-slot="{ field, errorMessage }"
                v-model="department"
              >
                <input
                  id="department"
                  v-bind="field"
                  placeholder="Saisissez un texte"
                  class="form-control"
                  :class="{ 'is-invalid': errorMessage }"
                />
              </Field>
              <ErrorMessage
                name="department"
                as="div"
                class="invalid-feedback"
              />
            </div>
            <!-- //? Commune -->
            <div
              class="mb-4 col-lg-4 col-md-6 col-sm-12 col-12"
              v-if="nativeCountry == 'Bénin'"
            >
              <label for="beninCity" class="form-label required">
                Ville
                <span class="fw-lighter"
                  ><i>(de l'école ou du lieu de travail)</i></span
                >
              </label>
              <Field
                name="beninCity"
                label="Ville"
                v-model="beninCity"
                v-slot="{ field, errorMessage }"
              >
                <select
                  class="form-select"
                  id="beninCity"
                  v-bind="field"
                  :class="{ 'is-invalid': errorMessage }"
                  aria-label="eeia candidature select"
                >
                  <option value="">Cliquer pour faire un choix</option>
                  <option
                    v-for="item in cities"
                    :key="item.value"
                    :value="item.value"
                  >
                    {{ item.text }}
                  </option>
                </select>
              </Field>
              <ErrorMessage
                name="beninCity"
                as="div"
                class="invalid-feedback"
              />
            </div>
            <!-- //? Commune -->
            <div class="mb-4 col-lg-4 col-md-6 col-sm-12 col-12" v-else>
              <label for="city" class="form-label required">
                Ville
                <span class="fw-lighter"
                  ><i>(de l'école ou du lieu de travail)</i></span
                >
              </label>
              <Field
                name="city"
                type="text"
                label="Ville"
                v-slot="{ field, errorMessage }"
                v-model="city"
              >
                <input
                  id="city"
                  v-bind="field"
                  placeholder="Saisissez un texte"
                  class="form-control"
                  :class="{ 'is-invalid': errorMessage }"
                />
              </Field>
              <ErrorMessage name="city" as="div" class="invalid-feedback" />
            </div>
          </div>

          <div class="row">
            <!-- //? Formation en IA -->
            <div class="mb-4 col-lg-4 col-md-6 col-sm-12 col-12">
              <label
                for="artificialIntelligenceTraining"
                class="form-label required"
                >Avez-vous suivi des formations en intelligence artificielle
                ?</label
              >
              <div class="form-check form-check">
                <Field
                  class="form-check-input"
                  type="radio"
                  name="artificialIntelligenceTraining"
                  id="artificialIntelligenceTraining1"
                  value="Oui"
                  v-model="artificialIntelligenceTraining"
                />
                <label
                  class="form-check-label"
                  for="artificialIntelligenceTraining1"
                >
                  Oui
                </label>
              </div>
              <div class="form-check form-check">
                <Field
                  class="form-check-input"
                  type="radio"
                  name="artificialIntelligenceTraining"
                  id="artificialIntelligenceTraining2"
                  value="Non"
                  v-model="artificialIntelligenceTraining"
                />
                <label
                  class="form-check-label"
                  for="artificialIntelligenceTraining2"
                >
                  Non
                </label>
              </div>
              <ErrorMessage
                name="artificialIntelligenceTraining"
                as="div"
                class="invalid-feedback"
              />
            </div>
            <!-- //? Formation en IA Organisateur -->
            <div
              class="mb-4 col-lg-4 col-md-6 col-sm-12 col-12"
              v-if="artificialIntelligenceTraining == 'Oui'"
            >
              <label
                for="artificialIntelligenceTrainingCompany"
                class="form-label required"
                >Quels organismes en étaient les organisateurs ?</label
              >
              <Field
                name="artificialIntelligenceTrainingCompany"
                v-model="artificialIntelligenceTrainingCompany"
                type="text"
                label="ci-dessous"
                v-slot="{ field, errorMessage }"
              >
                <input
                  id="artificialIntelligenceTrainingCompany"
                  v-bind="field"
                  placeholder="Saisissez un texte"
                  class="form-control"
                  :class="{ 'is-invalid': errorMessage }"
                />
              </Field>
              <ErrorMessage
                name="artificialIntelligenceTrainingCompany"
                as="div"
                class="invalid-feedback"
              />
            </div>
          </div>
          <div class="row">
            <!-- //? Métier en IA -->
            <div class="mb-4 col-lg-4 col-md-6 col-sm-12 col-12">
              <label
                for="artificialIntelligenceBusiness"
                class="form-label required"
                >Avez-vous exercé un métier lié à l'intelligence artificielle ou
                aux mathématiques ?</label
              >
              <div class="form-check form-check">
                <Field
                  class="form-check-input"
                  type="radio"
                  name="artificialIntelligenceBusiness"
                  id="artificialIntelligenceBusiness1"
                  value="Oui"
                  v-model="artificialIntelligenceBusiness"
                />
                <label
                  class="form-check-label"
                  for="artificialIntelligenceBusiness1"
                >
                  Oui
                </label>
              </div>
              <div class="form-check form-check">
                <Field
                  class="form-check-input"
                  type="radio"
                  name="artificialIntelligenceBusiness"
                  id="artificialIntelligenceBusiness2"
                  value="Non"
                  v-model="artificialIntelligenceBusiness"
                />
                <label
                  class="form-check-label"
                  for="artificialIntelligenceBusiness2"
                >
                  Non
                </label>
              </div>
              <ErrorMessage
                name="artificialIntelligenceBusiness"
                as="div"
                class="invalid-feedback"
              />
            </div>
            <!-- //? Métier en IA Mots clés -->
            <div
              class="mb-4 col-lg-4 col-md-6 col-sm-12 col-12"
              v-if="artificialIntelligenceBusiness == 'Oui'"
            >
              <label
                for="artificialIntelligenceBusinessWords"
                class="form-label required"
                >Donnez trois mots-clés qui décrivent ce métier.</label
              >
              <Field
                name="artificialIntelligenceBusinessWords"
                v-model="artificialIntelligenceBusinessWords"
                type="text"
                label="ci-dessous"
                v-slot="{ field, errorMessage }"
              >
                <input
                  id="artificialIntelligenceBusinessWords"
                  v-bind="field"
                  placeholder="Saisissez un texte"
                  class="form-control"
                  :class="{ 'is-invalid': errorMessage }"
                />
              </Field>
              <ErrorMessage
                name="artificialIntelligenceBusinessWords"
                as="div"
                class="invalid-feedback"
              />
            </div>
          </div>

          <div class="mt-2">
            <button
              class="btn btn-lg btn-primary"
              @click="$emit('to-previous')"
              type="button"
            >
              Précédent
            </button>
            <button
              class="btn btn-lg btn-primary ms-2"
              id="submit-button"
              type="submit"
            >
              Suivant
            </button>
          </div>

          <div class="row mb-4">
            <span class="text-danger d-none" id="form-invalid-error"
              ><i v-if="!meta.valid">
                Une ou plusieurs erreurs sont présentes dans le formulaire.</i
              ></span
            >
            <div class="mt-3 fst-italic">
              <span class="text-muted">
                <label class="required"></label> Obligatoire
              </span>
            </div>
          </div>
        </div>
      </form>
    </VeeForm>
  </div>
</template>

<script>
import { countries } from "./../countries";
import { cities } from "./../cities";
import { departments } from "./../departments";
import { fields } from "./../fields";
import { Form as VeeForm, Field, ErrorMessage } from "vee-validate";
import { defineRule } from "vee-validate";
import { required } from "@vee-validate/rules";

defineRule("required", required);
defineRule("required_if_training_ia", (value, [inputField]) => {
  if (inputField == "Oui") {
    if (!value) {
      return "Ce champs est obligatoire";
    }
  }

  return true;
});
defineRule("required_if_business_ia", (value, [inputField]) => {
  if (inputField == "Oui") {
    if (!value) {
      return "Ce champs est obligatoire";
    }
  }

  return true;
});
defineRule("required_if_other", (value, [inputField]) => {
  if (inputField == "other") {
    if (!value) {
      return "Ce champs est obligatoire";
    }
  }

  return true;
});
defineRule("required_if_student", (value, [inputField]) => {
  if (inputField == "Etudiant(e)") {
    if (!value) {
      return "Ce champs est obligatoire";
    }
  }

  return true;
});
defineRule("required_if_schoolboy_and_other", (value, [inputField]) => {
  if (inputField == "Elève" && inputField != "other") {
    if (!value) {
      return "Ce champs est obligatoire";
    }
  }

  return true;
});
defineRule("required_if_benin", (value, [inputField]) => {
  if (inputField == "Bénin") {
    if (!value) {
      return "Ce champs est obligatoire";
    }
  }

  return true;
});
defineRule("required_if_not_benin", (value, [inputField]) => {
  if (inputField != "Bénin") {
    if (!value) {
      return "Ce champs est obligatoire";
    }
  }

  return true;
});

defineRule("required_if_professional", (value, [inputField]) => {
  if (inputField == "Professionnel(le)") {
    if (!value) {
      return "Ce champs est obligatoire";
    }
  }

  return true;
});
defineRule("required_if_teacher", (value, [inputField]) => {
  if (inputField == "Enseignant(e)") {
    if (!value) {
      return "Ce champs est obligatoire";
    }
  }

  return true;
});

import { setLocale } from "@vee-validate/i18n";
import { configure } from "vee-validate";
import { localize } from "@vee-validate/i18n";
import fr from "@vee-validate/i18n/dist/locale/fr.json";
configure({
  generateMessage: localize({
    fr,
  }),
});
setLocale("fr");

import { library } from "@fortawesome/fontawesome-svg-core";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
library.add(faInfoCircle, faExternalLinkAlt);

export default {
  name: "EeiaCareer",
  inject: [
    "ToastSuccess",
    "ToastError",
    "currentYearUrl",
    "AlertWarning",
    "AlertSuccess",
    "AlertError",
  ],
  components: {
    // Rename form to avoid confusion with native `form`
    VeeForm,
    Field,
    ErrorMessage,
  },
  props: {
    previousData: Object,
    step: Number,
    nativeCountry: String,
  },
  mounted() {
    document
      .getElementById("submit-button")
      .addEventListener("click", function () {
        document
          .getElementById("form-invalid-error")
          .classList.remove("d-none");
      });
  },
  data() {
    const schema = {
      category: "required",
      otherCategory: "required_if_other:@category",
      level: "required",
      job: "required_if_schoolboy_and_other:@category",
      studyField: "required_if_student:@category",
      otherStudyField: "required_if_other:@studyField",
      establishment: "required",
      city: "required_if_not_benin:@countryNative",
      beninCity: "required_if_benin:@countryNative",
      country: "required_if_not_benin:@countryNative",
      beninDepartment: "required_if_benin:@countryNative",
      workingSector: "required_if_professional:@category",
      otherWorkingSector: "required_if_other:@workingSector",
      teacherTaughtSubject: "required_if_teacher:@category",
      teacherStudyField: "required_if_teacher:@category",
      otherTeacherStudyField: "required_if_other:@teacherStudyField",
      artificialIntelligenceTraining: "required",
      artificialIntelligenceTrainingCompany:
        "required_if_training_ia:@artificialIntelligenceTraining",
      artificialIntelligenceBusiness: "required",
      artificialIntelligenceBusinessWords:
        "required_if_business_ia:@artificialIntelligenceBusiness",
    };

    return {
      schema,
      category: this.previousData.category,
      otherCategory: this.previousData.otherCategory,
      level: this.previousData.level,
      job: this.previousData.job == "null" ? "" : this.previousData.job,
      establishment: this.previousData.establishment,
      city: this.previousData.city,
      beninCity: this.previousData.beninCity,
      country: this.previousData.country,
      department: this.previousData.department,
      beninDepartment: this.previousData.beninDepartment,
      studyField: this.previousData.studyField,
      otherStudyField: this.previousData.otherStudyField,
      countries: countries,
      cities: cities,
      departments: departments,
      fields: fields,
      workingSector: this.previousData.workingSector,
      otherWorkingSector: this.previousData.otherWorkingSector,
      teacherTaughtSubject: this.previousData.teacherTaughtSubject,
      teacherStudyField: this.previousData.teacherStudyField,
      otherTeacherStudyField: this.previousData.otherTeacherStudyField,
      artificialIntelligenceTraining:
        this.previousData.artificialIntelligenceTraining,
      artificialIntelligenceTrainingCompany:
        this.previousData.artificialIntelligenceTrainingCompany,
      countryNative: this.nativeCountry,
      artificialIntelligenceBusiness:
        this.previousData.artificialIntelligenceBusiness,
      artificialIntelligenceBusinessWords:
        this.previousData.artificialIntelligenceBusinessWords,
    };
  },
  watch: {
    nativeCountry: {
      handler(newValue) {
        this.countryNative = newValue
      },
      deep: true
    }
  },
  methods: {
    onSubmit(values, actions) {
      // let $this = this
      // let promiseValues = new Promise(function (resolve) {
      //   console.log($this.$refs)
      //   values.step = 1;
      //   resolve(values);
      // });

      this.beforeSendPromise(values, this).then((formData) => {
        this.$http
          .post("new-candidacies", formData)

          .then(() => {
            this.$emit("career-sent", this.category);
            this.$emit("to-next");
          })
          .catch((error) => {
            if (error.response.status == 422) {
              this.ToastError.fire({
                title: "Veuillez bien remplir le formulaire et réessayez.",
              });

              let errors = error.response.data.errors;
              for (const key in errors) {
                if (Object.prototype.hasOwnProperty.call(errors, key)) {
                  const element = errors[key];
                  actions.setFieldError(key, element);
                }
              }
            } else if (error.response.status == 403) {
              this.AlertError.fire({
                icon: "error",
                title: "Non autorisé",
                html: "Vous ne pouvez plus modifier votre candidature.",
              });
            } else {
              this.ToastError.fire({
                title: "Une erreur s'est produite, veuillez réessayer.",
              });
            }
          });
      });
    },
    beforeSendPromise(values, $this) {
      return new Promise(function (resolve) {
        values.step = 2;
        // values.attachments = $this.$refs.attachments.files.item(0);
        let formData = new FormData();
        formData.append("step", 2);

        formData.append("category", $this.category);

        if ($this.otherCategory == null) {
          formData.append("otherCategory", "");
        } else {
          formData.append("otherCategory", $this.otherCategory);
        }

        formData.append("level", $this.level);
        formData.append("job", $this.job);
        formData.append("establishment", $this.establishment);

        if ($this.city == null) {
          formData.append("city", "");
        } else {
          formData.append("city", $this.city);
        }

        if ($this.beninCity == null) {
          formData.append("beninCity", "");
        } else {
          formData.append("beninCity", $this.beninCity);
        }

        formData.append("country", $this.country);
        formData.append("department", $this.department);

        if ($this.beninDepartment == null) {
          formData.append("beninDepartment", "");
        } else {
          formData.append("beninDepartment", $this.beninDepartment);
        }

        if ($this.studyField == null) {
          formData.append("studyField", "");
        } else {
          formData.append("studyField", $this.studyField);
        }

        if ($this.otherStudyField == null) {
          formData.append("otherStudyField", "");
        } else {
          formData.append("otherStudyField", $this.otherStudyField);
        }

        if ($this.workingSector == null) {
          formData.append("workingSector", "");
        } else {
          if ($this.category != "Professionnel(le)") {
            formData.append("workingSector", "");
          } else {
            formData.append("workingSector", $this.workingSector);
          }
        }

        if ($this.otherWorkingSector == null) {
          formData.append("otherWorkingSector", "");
        } else {
          if ($this.category != "Professionnel(le)") {
            formData.append("otherWorkingSector", "");
          } else {
            formData.append("otherWorkingSector", $this.otherWorkingSector);
          }
        }

        if ($this.teacherTaughtSubject == null) {
          formData.append("teacherTaughtSubject", "");
        } else {
          if ($this.category != "Enseignant(e)") {
            formData.append("teacherTaughtSubject", "");
          } else {
            formData.append("teacherTaughtSubject", $this.teacherTaughtSubject);
          }
        }

        if ($this.teacherStudyField == null) {
          formData.append("teacherStudyField", "");
        } else {
          if ($this.category != "Enseignant(e)") {
            formData.append("teacherStudyField", "");
          } else {
            formData.append("teacherStudyField", $this.teacherStudyField);
          }
        }

        if ($this.otherTeacherStudyField == null) {
          formData.append("otherTeacherStudyField", "");
        } else {
          if ($this.category != "Enseignant(e)") {
            formData.append("otherTeacherStudyField", "");
          } else {
            formData.append(
              "otherTeacherStudyField",
              $this.otherTeacherStudyField
            );
          }
        }

        formData.append(
          "artificialIntelligenceTraining",
          $this.artificialIntelligenceTraining
        );
        formData.append(
          "artificialIntelligenceBusiness",
          $this.artificialIntelligenceBusiness
        );
        formData.append("countryNative", $this.countryNative);

        if ($this.artificialIntelligenceTrainingCompany == null) {
          formData.append("artificialIntelligenceTrainingCompany", "");
        } else {
          formData.append(
            "artificialIntelligenceTrainingCompany",
            $this.artificialIntelligenceTrainingCompany
          );
        }

        if ($this.artificialIntelligenceBusinessWords == null) {
          formData.append("artificialIntelligenceBusinessWords", "");
        } else {
          formData.append(
            "artificialIntelligenceBusinessWords",
            $this.artificialIntelligenceBusinessWords
          );
        }

        resolve(formData);
      });
    },
    categoryChanged() {
      this.level = "";
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media (min-width: 768px) {
  .form-control,
  .form-select {
    line-height: 2;
  }
}

.section-title {
  border-bottom: 3px solid #3490dc;
}
</style>
