<template>
  <div class="container px-sm-0 bg-light">
    <div class="row p-sm-5 pt-3">
      <div class="col">
        <div class="col mb-4">
          <h4 class="section-title">Nous contacter</h4>
        </div>
        <ul>
          <li><u>Téléphone</u>: +229 91 05 15 15 / 91 13 87 87</li>
          <li>
            <u>Email</u>:
            <a href="mailto:eeia@beninexcellence.org"
              >eeia@beninexcellence.org</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contact",
  mounted() {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
.section-title {
  border-bottom: 3px solid #3490dc;
}
</style>
