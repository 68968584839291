
export const fields = [

    { value: "Agriculture – agroalimentaire", text : "Agriculture – agroalimentaire"},
    { value: "Architecture, urbanisme et aménagement du territoire", text : "Architecture, urbanisme et aménagement du territoire"},
    { value: "Arts, culture, design et mode", text : "Arts, culture, design et mode"},
    { value: "Biologie", text : "Biologie"},
    { value: "Chimie", text : "Chimie"},
    { value: "Communication et journalisme", text : "Communication et journalisme"},
    { value: "Droit", text : "Droit"},
    { value: "Environnement et sciences de la terre", text : "Environnement et sciences de la terre"},
    { value: "Informatique", text : "Informatique"},
    { value: "Langues et lettres", text : "Langues et lettres"},
    { value: "Management, gestion, finances et commerce", text : "Management, gestion, finances et commerce"},
    { value: "Mathématiques", text : "Mathématiques"},
    { value: "Philosophie", text : "Philosophie"},
    { value: "Physique", text : "Physique"},
    { value: "Santé et professions sociales", text : "Santé et professions sociales"},
    { value: "Sciences de l’éducation", text : "Sciences de l’éducation"},
    { value: "Sciences de l’ingénieur", text : "Sciences de l’ingénieur"},
    { value: "Sciences économiques", text : "Sciences économiques"},
    { value: "Sciences politiques et droit", text : "Sciences politiques et droit"},
    { value: "Sciences humaines et sociales", text : "Sciences humaines et sociales"},
    { value: "Sociologie", text : "Sociologie"},
    { value: "Sports", text : "Sports"},
    { value: "Tourisme, hôtellerie et restauration", text : "Tourisme, hôtellerie et restauration"},
    { value: "Transport et logistique", text : "Transport et logistique"},
    { value: "other", text : "Autre"},

];


    



